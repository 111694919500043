export const REMOTE_CONTROL_INSTRUCTION = 'REMOTE_CONTROL_INSTRUCTION';
export const MUTE_EVERYBODY_IN_CALL = 'MUTE_EVERYBODY_IN_CALL';
export const DISCONNECT_EVERYBODY_IN_CALL = 'DISCONNECT_EVERYBODY_IN_CALL';
export const TURN_OFF_PARTICIPANT_DEVICE = "TURN_OFF_PARTICIPANT_DEVICE";

export const remoteControlInstruction = (action) => ({
  type: REMOTE_CONTROL_INSTRUCTION,
  payload: action,
});

export const muteEverybodyInCall = () => ({ type: REMOTE_CONTROL_INSTRUCTION, payload: MUTE_EVERYBODY_IN_CALL });
export const disconnectEverybodyInCall = () => ({
  type: REMOTE_CONTROL_INSTRUCTION,
  payload: DISCONNECT_EVERYBODY_IN_CALL,
});

export const disableParticipantDevice = (device, participantId) => ({type: TURN_OFF_PARTICIPANT_DEVICE, payload: {participantId, device}})