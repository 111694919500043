import {
    GET_MEDIA_PERMISSIONS,
    INIT_VIDEO,
    LOCAL_DEVICES_FETCHED,
    MUTE_LOCAL_AUDIO,
    MUTE_LOCAL_SCREEN,
    MUTE_LOCAL_VIDEO, muteLocalAudio, muteLocalScreen, muteLocalVideo, SET_AV_PARTICIPANTS, SET_DOMINANT_SPEAKER,
    SET_HAS_MIC, SET_LOCAL_TRACK, SET_PINNED_PARTICIPANT,
    setLocalDevices, TOGGLE_LOCAL_VIDEO, TOGGLE_SCREEN_SHARE,
    toggleLocalAudio,
    toggleLocalVideo,
    toggleScreenShare,
    TURN_OFF_LOCAL_DEVICES, UPDATE_AV_PARTICIPANT,
    UPDATE_AV_PARTICIPANTS, UPDATE_PARTICIPANT_STATUS,
} from '../actions';
import {getDevices, getPermissions} from "./helpers";
import {makeVideoHandlers} from "./handlers";
import {sendMessage} from "../../booking/actions";


const videoMiddleware = ({dispatch, getState}) => {
    const fetchDevices = () => getDevices(dispatch);
    const {
        onUpdateAVParticipants,
        setParticipantsOrder,
        updateMainParticipant,
        isAudioOnIndicator
    } = makeVideoHandlers({dispatch, getState})

    return (next) => (action) => {
        next(action);
        // All switch cases are scoped with {} to allow variable names to be redeclared in the new scope
        switch (action.type) {
            case GET_MEDIA_PERMISSIONS: {
                getPermissions(dispatch)
                break;
            }
            case INIT_VIDEO: {
                fetchDevices()
                navigator.mediaDevices.addEventListener('devicechange', fetchDevices);
                break;
            }
            case LOCAL_DEVICES_FETCHED: {
                dispatch(setLocalDevices(action.payload));
                break;
            }
            case UPDATE_AV_PARTICIPANTS: {
                onUpdateAVParticipants(action.payload);
                break;
            }
            case SET_AV_PARTICIPANTS:
            case UPDATE_PARTICIPANT_STATUS:
            case UPDATE_AV_PARTICIPANT:{
                setParticipantsOrder();
                break;
            }
            case SET_LOCAL_TRACK:
            case TOGGLE_LOCAL_VIDEO:
            case TOGGLE_SCREEN_SHARE:
            case SET_PINNED_PARTICIPANT:
            case SET_DOMINANT_SPEAKER: {
                updateMainParticipant();
                break;
            }
            case SET_HAS_MIC: {
                dispatch(sendMessage({ action: 'websocket-api', request: 'set-mic-participant',
                    setHasMic: action.payload.hasMic, participantId: action.payload.participantId }));
                break;
            }
            case MUTE_LOCAL_AUDIO: {
                const isAudioEnabled = isAudioOnIndicator();
                if (isAudioEnabled)
                    dispatch(toggleLocalAudio());
                break;
            }
            case MUTE_LOCAL_VIDEO: {
                let isVideoEnabled = getState().audioVideo?.localTracks?.video?.isEnabled;
                if (isVideoEnabled)
                    dispatch(toggleLocalVideo());
                break;
            }
            case MUTE_LOCAL_SCREEN: {
                let screen = getState().audioVideo.localTracks?.screen;
                    if (!!screen)
                        dispatch(toggleScreenShare());
                break;
            }
            case TURN_OFF_LOCAL_DEVICES: {
                dispatch(muteLocalAudio());
                dispatch(muteLocalVideo());
                dispatch(muteLocalScreen());
                break;
            }
            default:
                break;
        }
    };
};

export default videoMiddleware;
