import React, {useEffect, useState} from 'react';

import StarRatings from 'react-star-ratings';
import { useParams } from 'react-router-dom';

import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { styled, MuiThemeProvider } from '@material-ui/core/styles';
import { Fade } from '@material-ui/core';

import theme from './theme';
import { API_ROOT_URL } from '../../consts';
import ICD10Dialog from '../ICD10Dialog/ICD10Dialog';
import {useDispatch, useSelector} from 'react-redux';
import {setConsultState} from "../../redux/app/actions";
import useHeight from "../../layout/useHeight";


export default function PostConsult() {
  const booking = useSelector(state => state.bookingDetails?.room?.booking_summary);
  const isFacilitator = useSelector(state => state.audioVideo?.localState?.isFacilitator);

  const [rating, setRating] = useState(0);
  const consultState = useSelector(state => state.app.consultState);
  const [question, setQuestion] = useState('');
  const [scale, setScale] = useState(['', '']);
  const [showICD10Dialog, setShowICD10Dialog] = useState(false);
  const height = useHeight();

  const { consult_token_id } = useParams();
  const dispatch = useDispatch();

  const autoPayment = booking?.auto_payment;
  const patientRatingScale = booking?.patient_rating_scale;
  const patientRatingQuestion = booking?.patient_rating_question;

  useEffect(() => {
    if (isFacilitator) {
      setQuestion('How was the audio and video?');
      setScale(['Very bad', 'Very good']);

      if (autoPayment) {
        setShowICD10Dialog(true);
      }
    } else if (!isFacilitator) {
      if (patientRatingQuestion && patientRatingScale) {
        setQuestion(patientRatingQuestion);
        setScale(patientRatingScale.split(','));
      } else {
        setQuestion('How likely are you to recommend this service to your friends and family?');
        setScale(['Unlikely', 'Very likely']);
      }
    }
  }, [isFacilitator, autoPayment, patientRatingScale, patientRatingQuestion]);

  const saveRating = (rating) => {
    setRating(rating);

    const endpoint = `${API_ROOT_URL}/save-rating`;
    const params = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ token: consult_token_id, rating: rating }),
    };
    fetch(endpoint, params).then(res => {
      if (!res.ok) {
        console.log('failed to save rating - ignoring (best effort attempt)');
      }
    });
  };

  const onRejoin = () => {
    dispatch(setConsultState('pre-consult'));
  };

  const renderRatingCardContent = () => {
    return (
      <Grid>
        <ICD10Dialog dismiss={() => setShowICD10Dialog(false)} show={showICD10Dialog} />

        <Fade in={rating !== 0} timeout={{ enter: 2000, exit: 0 }}>
          <Question variant="h6" align="center" gutterBottom>
            Thank you for your feedback!
          </Question>
        </Fade>

        <Fade in={rating === 0} timeout={{ enter: 2000, exit: 0 }}>
          <RatingCard raised>
            <CardContent>
              <Question variant="h6" align="center" gutterBottom>
                {question}
              </Question>

              <RatingContainer>
                <StarRatings
                  rating={rating}
                  starRatedColor="#1D3443"
                  starHoverColor="#1D3443"
                  changeRating={saveRating}
                  numberOfStars={5}
                  starDimension="40px"
                  name="rating"
                />
              </RatingContainer>

              <HintTextContainer>
                <HintText>{scale[0]}</HintText>
                <div />
                <HintText>{scale[1]}</HintText>
              </HintTextContainer>
            </CardContent>
          </RatingCard>
        </Fade>
      </Grid>
    );
  };

  const endOfConsultContent = () => {
    return (
      <React.Fragment>
        <Typography variant="h1" component="h1" align="center">
          You've left the consult
        </Typography>
        <ButtonsContainer>
          <Button color="primary" variant="outlined" onClick={() => onRejoin(false)}>
            Rejoin
          </Button>
        </ButtonsContainer>

        {renderRatingCardContent()}
      </React.Fragment>
    );
  };

  const reconnectingContent = () => {
    return (
      <React.Fragment>
        <LightTitle variant="h4" align="center">
          Your network connection was interrupted
        </LightTitle>
        <Typography align="center">Please wait whilst we try to reconnect you...</Typography>
      </React.Fragment>
    );
  };

  return (
    <MuiThemeProvider theme={theme}>
      <Container style={{height}}>
        {consultState === 'reconnect' ? reconnectingContent() : endOfConsultContent()}
      </Container>
    </MuiThemeProvider>
  );
}

const Container = styled('div')(({ theme }) => ({
  display: 'grid',
  backgroundColor: theme.palette.background.paper,
  gridTemplateColumns: `1fr`,
  gridTemplateRows: 'auto auto auto minmax(0, 1fr)',
  gridGap: '6px',
  padding: '20px',
}));

const Grid = styled('div')(({ theme }) => ({
  display: 'grid',
}));

const ButtonsContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  justifyItems: 'center',
  margin: '1em',
}));

const RatingCard = styled(Card)(({ theme }) => ({
  marginTop: '1em',
  maxWidth: '375px',
  minWidth: '300px',
  justifySelf: 'center',
  padding: 15,
}));

const HintTextContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'auto minmax(0, 1fr) auto',
  width: '100%',
}));

const HintText = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  color: theme.palette.text.secondary,
}));

const Question = styled(Typography)(({ theme }) => ({
  marginBottom: '1em',
}));

const RatingContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  justifyItems: 'center',
  width: '100%',
  margin: 0,
  padding: 0,
}));

const LightTitle = styled(Typography)({
  fontWeight: 300,
  paddingTop: 15,
  paddingBottom: 15,
});
