import {
    SET_CONSULT_STATE, SET_ERROR_MESSAGE,
    SET_READY_FOR_CONSULT, SET_SHOW_WAITING_ROOM, TOGGLE_VIEW_MODE
} from "../actions";
import {SET_PERMISSION_STEP, SET_PINNED_PARTICIPANT} from "../../audioVideo/actions";

const INITIAL_STATE = {
    consultState: 'request-permission',
    isReadyForConsult: false,
    viewMode: "speaker",
    errorMessage: null,
    showWaitingRoom: false,
    permissionStep: "unknown"
};

const appReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_CONSULT_STATE:
            return {...state, consultState: action.payload}
        case SET_READY_FOR_CONSULT:
            return {...state, isReadyForConsult: action.payload}
        case TOGGLE_VIEW_MODE:
            if (state.viewMode === 'speaker')
                return {...state, viewMode: "grid"}
            return {...state, viewMode: "speaker"}
        case SET_ERROR_MESSAGE:
            return {...state, errorMessage: action.payload}
        case SET_PINNED_PARTICIPANT:
            return {...state, viewMode: 'speaker'}
        case SET_SHOW_WAITING_ROOM:
            return {...state, showWaitingRoom: action.payload}
        case SET_PERMISSION_STEP:
            return {...state, permissionStep: action.payload}
        default:
            return state;
    }
};

export default appReducer;
