import {
    CONNECT_TO_ROOM, FETCH_ROOM_SUBSCRIPTIONS
} from '../actions';
import {fetchToken, getPreferredVideoDeviceOptions, initTracks} from "./helpers";
import {connect} from "twilio-video";
import useConnectionOptions from "../../../hooks/useConnectOptions";
import {makeTwilioVideoHandlers} from "./handlers";
import {setConsultState, setErrorMessage} from "../../app/actions";
import {
    setRoomState,
    setLocalDevices,
    setLocalTracks,
    FLIP_CAMERA, LOCAL_DEVICES_FETCHED, SET_AUDIO_INPUT_DEVICE, SET_VIDEO_DEVICE,
    TOGGLE_LOCAL_AUDIO, TOGGLE_LOCAL_VIDEO, END_CALL, TOGGLE_SCREEN_SHARE
} from "../../audioVideo/actions";
import {sendMessage} from "../../booking/actions";


const twilioMiddleware = ({dispatch, getState}) => {
    let initialized = false;

    const {
        cleanupRoom,
        onToggleVideo,
        onToggleAudio,
        onConnectToRoom,
        updateLocalTracks,
        onChangeVideoDevice,
        onFlipCamera,
        onChangeAudioDevice,
        onToggleScreenShare
    } = makeTwilioVideoHandlers({dispatch, getState});


    return (next) => (action) => {
        next(action);

        // All switch cases are scoped with {} to allow variable names to be redeclared in the new scope
        switch (action.type) {
            case LOCAL_DEVICES_FETCHED: {
                if (!initialized) {
                    initialized = true;
                    let preferredVideoDeviceOptions = getPreferredVideoDeviceOptions(action.payload);
                    initTracks({...preferredVideoDeviceOptions}).then(tracks => updateLocalTracks(tracks));
                }
                break;
            }
            case SET_VIDEO_DEVICE: {
                onChangeVideoDevice(action.payload);
                break;
            }
            case END_CALL: {
                cleanupRoom();
                const video = getState().audioVideo.localTracks?.video?.track;
                if (video) video.stop();
                initialized = false;

                dispatch(setLocalDevices([]))
                dispatch(setLocalTracks({video: null, audio: null, tracks: []}))
                dispatch(setConsultState('post-consult'))
                break;
            }
            case CONNECT_TO_ROOM: {
                cleanupRoom();
                const {id} = action.payload;
                const connectionOptions = useConnectionOptions();
                connectionOptions.tracks = [];
                dispatch(setRoomState('Fetching token...'))
                fetchToken(id, dispatch)
                    .then(token => {
                        if (token){
                            dispatch(setRoomState('Connecting...'))
                            return token.token;
                        }
                        dispatch(setRoomState('Failed to fetch token'))
                        return null;
                    })
                    .then(token => {
                        let isFacilitator = getState().audioVideo.localState.isFacilitator;
                        const isGroupConsult = getState().bookingDetails.room?.booking_summary?.group_consult === 'Yes';
                        if (isGroupConsult){
                            connectionOptions.automaticSubscription= false;
                        }
                        else{
                            connectionOptions.dominantSpeaker= true;
                            connectionOptions.dominantSpeakerPriority = 'high';
                        }
                        connectionOptions.bandwidthProfile.video.maxTracks = isFacilitator ? 20 : 4;
                        return connect(token, connectionOptions).catch((e)=> {
                            console.log(e)
                            dispatch(setErrorMessage(e.toString()));
                            throw(e)
                        })
                    })
                    .then(onConnectToRoom)

                break;
            }
            case FETCH_ROOM_SUBSCRIPTIONS:
                dispatch(sendMessage({ action: 'websocket-api', request: 'get-room-subscriptions' }));
                break;
            case TOGGLE_LOCAL_VIDEO: {
                onToggleVideo();
                break;
            }

            case FLIP_CAMERA: {
                onFlipCamera(action.payload);
                break;
            }

            case TOGGLE_LOCAL_AUDIO: {
                onToggleAudio();
                break;
            }

            case TOGGLE_SCREEN_SHARE: {
                onToggleScreenShare();
                break;
            }

            case SET_AUDIO_INPUT_DEVICE: {
                onChangeAudioDevice(action.payload);
                break;
            }

            default:
                break;
        }
    };
};

export default twilioMiddleware;
