import React, {} from "react";

import {useSelector} from "react-redux";
import ParticipantAudioTracks from "./ParticipantAudioTracks";

const ParticipantsAudio = () => {
    const avParticipantsAudioTracks = useSelector(state => state.audioVideo.remoteAudioTracks);

    return Object.keys(avParticipantsAudioTracks).map((avParticipantID) => {
        return <ParticipantAudioTracks key={avParticipantID} participantId={avParticipantID}/>
    })
}

export default React.memo(ParticipantsAudio);