import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {ParticipantControl} from "../../../layout/layout";
import SettingsVoiceIcon from '@material-ui/icons/SettingsVoice';
import {setParticipantHasMic} from "../../../redux/audioVideo/actions";
import CenterFocusStrongIcon from '@material-ui/icons/CenterFocusStrong';
import styled from "styled-components";
import {disableParticipantDevice} from "../../../redux/remoteControl/actions";

const ParticipantHasMicIndicator = ({id}) => {
    const isFacilitator = useSelector(state => state.audioVideo?.localState?.isFacilitator);
    const isParticipantHandUp = useSelector(state => state.audioVideo.participants[id]?.handUp)
    const hasMic = useSelector(state => {
        if (id === 'local')
            return state.audioVideo.localState?.hasMic
        return state.audioVideo.participants[id]?.hasMic
    });
    const isGroupConsult = useSelector((state) => state.bookingDetails?.room?.booking_summary?.group_consult) === 'Yes';
    const dispatch = useDispatch();

    const onClick = (e) => {
        e.stopPropagation();
        dispatch(setParticipantHasMic(id, !hasMic))
        if (isParticipantHandUp){
            dispatch(disableParticipantDevice('hand', id));
        }
    };

    if (!isGroupConsult) return null;

    if (isFacilitator && id === 'local') return null;
    if (!hasMic && id === 'local') return null;
    if (!hasMic && !isFacilitator) return null;

   if (!hasMic)
       return (
        <ParticipantControl onClick={onClick} size={"small"}>
            {<SettingsVoiceIcon style={{color: '#fff'}}/>}
        </ParticipantControl>
    );

    return (
        <ParticipantControl onClick={onClick} size={"small"}>
            {id === 'local' ? <BlinkingIcon/> : <LiveIcon/>}
        </ParticipantControl>
    );
};

export default ParticipantHasMicIndicator;

const BlinkingIcon = styled(CenterFocusStrongIcon)`
  color: #ff0000;
  animation: blink 1s ease-in infinite;
  @keyframes blink {
      from, to { opacity: 1 }
      50% { opacity: 0.1 }
    }
`;

const LiveIcon = styled(CenterFocusStrongIcon)`
  color: #ff0000;
`;