import React, {useCallback, useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles, styled } from '@material-ui/core/styles';

import Controls from '../Controls/Controls';
import LocalVideoPreview from '../LocalVideoPreview';

import GettingReady from './Instructions/GettingReady';
import ReadyToJoin from './Instructions/ReadyToJoin';
import ConnectedFromAnotherDevice from './Instructions/ConnectedFromAnotherDevice';

import PatientReady from './Instructions/PatientReady';
import PractitionerReady from './Instructions/PractitionerReady';
import FutureLink from './Instructions/FutureLink';
import InvalidLink from './Instructions/InvalidLink';
import StartingConsult from './Instructions/StartingConsult';
import WaitingToJoin from './Instructions/WaitingToJoin';
import {initVideo} from "../../redux/audioVideo/actions";
import {useParams} from "react-router-dom";
import {setReadyForConsult} from "../../redux/app/actions";
import {connectToRoom} from "../../redux/twilio/actions";
import {sendMessage, startConsult} from "../../redux/booking/actions";

const GRID_PADDING = 15;

const LocalVideoContainer = styled('div')({
  alignItems: 'end',
  justifyItems: 'center',
  width: '100%',
  height: '100%',
});

const useStyles = makeStyles(() => ({
  preJoinGrid: {
    display: 'grid',
    gridTemplateRows: (props) => `minmax(0, ${props.videoHeight/2}px) ${props.videoHeight}px`,
    gridTemplateColumns: '1fr',
    padding: GRID_PADDING,
  },
}));

const calculateVideoHeight = (height) => {
  let h = parseInt((height || "").replace('px', ""));
  return Math.floor(((h - GRID_PADDING * 2) / 3) * 2)
};

const calculateVideoWidth = (width) => Math.floor(width - GRID_PADDING * 2);

export default (props) => {
  const dispatch = useDispatch();

  const [videoHeight, setVideoHeight] = useState(calculateVideoHeight(props.height));
  const [videoWidth, setVideoWidth] = useState(calculateVideoWidth(props.width));
  const classes = useStyles({ videoHeight });

  const isReadyForConsult = useSelector(state => state.app.isReadyForConsult);

  const room = useSelector(s => s.bookingDetails.room);
  const connectedFromAnotherDevice = useSelector(s => s.bookingDetails.connectedFromAnotherDevice);
  const consultStarted = useSelector(s => s.bookingDetails.consultStarted);
  const isGroupConsult = room?.booking_summary?.group_consult === 'Yes';
  const shouldConnect = useSelector(s => s.socket.shouldConnect);
  const connected = useSelector(s => s.socket.connected);

  let {consult_token_id} = useParams();

  useEffect(() => {
    setVideoHeight(calculateVideoHeight(props.height));
    setVideoWidth(calculateVideoWidth(props.width));
  }, [props.height, props.width]);

  useEffect(()=>{
          dispatch(initVideo());
  },[dispatch])

  const onReady = () => {
    dispatch(sendMessage({ action: 'websocket-api', request: 'update-status', status: 'ready' }));
    dispatch(setReadyForConsult(true));
  };

  const onReconnect = () => {
    dispatch(setReadyForConsult(false));
    props.connectSocket();
  };

  const onStartConsult = () => {
    dispatch(startConsult());
  };

  const onLaunchConsult = useCallback(() => {
      dispatch(connectToRoom({id: consult_token_id, name: `User ${consult_token_id}`}))
  }, [dispatch, consult_token_id]);

  const connectionIsInitialized = shouldConnect && (!connected || (connected && room === null));
  const hasValidToken = room && room?.token_is_valid && room?.token_is_for_today;
  const isValidFutureToken = room && room?.token_is_valid && !room?.token_is_for_today && !room?.token_is_expired;
  const isNotReadyForConsult = !isReadyForConsult;
  const isPatient = room?.user_type === 'patient';
  const isPractitioner = !isPatient;
  const isValidExpiredToken = room && room?.token_is_valid && room?.token_is_expired;
  const consultHasStarted = consultStarted || (room && room?.consult_started === 'Yes');
  const isNotGroupConsult = !isGroupConsult;
  const participantAdmissionStatus = useSelector(state => state.audioVideo.localState.admissionStatus);
  const participantNotInAllowedState = isPatient && participantAdmissionStatus !== 'allowed';
  const patientNotInAllowedState = isPatient && participantNotInAllowedState;

  const getInstructionToRender = () => {
    if (connectionIsInitialized) {
      return { instruction: GettingReady };
    } else if (connectedFromAnotherDevice) {
      return { instruction: ConnectedFromAnotherDevice, props: { onReconnect } };
    } else if (connected && isValidFutureToken) {
      return { instruction: FutureLink, props: { room } };
    } else if (connected && isValidExpiredToken) {
      return { instruction: InvalidLink, props: { room } };
    } else if (connected && hasValidToken && isNotReadyForConsult) {
      return { instruction: ReadyToJoin, props: { onReady, room } };
    } else if (isNotGroupConsult && isReadyForConsult && !consultHasStarted) {
      if (isPatient) return { instruction: PatientReady, props: { room } };
      if (isPractitioner) return { instruction: PractitionerReady, props: { room, onStartConsult } };
    } else if (isGroupConsult && isReadyForConsult && !consultHasStarted && isPractitioner) {
      return { instruction: PractitionerReady, props: { room, onStartConsult } };
    } else if (isGroupConsult && isReadyForConsult && patientNotInAllowedState) {
      return { instruction: WaitingToJoin, props: { room } };
    } else if (consultHasStarted) {
      return { instruction: StartingConsult, props: { room, onLaunchConsult } };
    }
    return null;
  };

  const renderInstruction = () => {
    let instructionToRender = getInstructionToRender();
    if (instructionToRender)
      return React.createElement(instructionToRender.instruction, { ...(instructionToRender.props || {}) });
    else return <InvalidLink/>;
  };

  return (
    <LocalVideoContainer className={classes.preJoinGrid}>
      {renderInstruction()}
      <LocalVideoPreview maxHeight={videoHeight} maxWidth={videoWidth}>
        <Controls isLocal />
      </LocalVideoPreview>
    </LocalVideoContainer>
  );
};
