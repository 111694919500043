import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    ParticipantLayoutDiv
} from "../../layout/layout";
import ParticipantVideoTrack from "./ParticipantVideoTrack";
import {PARTICIPANT_OFFLINE_STATUSES} from "../../consts";
import ParticipantFooter from "./ParticipantFooter";
import {setPinnedParticipant} from "../../redux/audioVideo/actions";
import Avatar from "./Avatar";
import ParticipantHeader from "./ParticipantHeader";


function Participant({participant}) {
    const videos = useSelector(store => store.audioVideo.remoteVideoTracks[participant.id]);
    const dispatch = useDispatch();

    const onSelectVideo =(track) => {
        if (!participant) return;
        dispatch(setPinnedParticipant({trackId: track?.id, id:participant.id}))
    };

    if (!videos || videos.length === 0){
        if (PARTICIPANT_OFFLINE_STATUSES.indexOf(participant.status) > -1) return null;
        return (
            <ParticipantLayoutDiv  onClick={()=>{onSelectVideo(null)}}>
                <ParticipantHeader participantID={participant.id}/>
                <Avatar name={participant.name ? participant.name : participant.id}/>
                <ParticipantFooter id={participant.id}/>
            </ParticipantLayoutDiv>
        );
    }

    return videos.map(track => (
        <ParticipantLayoutDiv key={track.id} onClick={()=>{onSelectVideo(track)}}>
                <ParticipantHeader participantID={participant.id}/>
                <ParticipantVideoTrack
                    key={track.id}
                    track={track}
                    identity={participant.name}
                />
                <ParticipantFooter id={participant.id}/>
        </ParticipantLayoutDiv>
    ))

}

export default React.memo(Participant);