import React, { useCallback, useEffect, useState } from 'react';
import FlipCameraIosIcon from '@material-ui/icons/FlipCameraIos';
import { IconButton } from '@material-ui/core';
import {useDispatch, useSelector} from "react-redux";
import {flipCamera} from "../../redux/audioVideo/actions";

export default function FlipCameraButton() {

  const [supportsFacingMode, setSupportsFacingMode] = useState(null);
  const videoTrack = useSelector(state => state.audioVideo.localTracks.video?.track);
  const facingMode = videoTrack?.mediaStreamTrack.getSettings().facingMode;
  const dispatch = useDispatch();

  useEffect(() => {
    // The 'supportsFacingMode' variable determines if this component is rendered
    // If 'facingMode' exists, we will set supportsFacingMode to true.
    // However, if facingMode is ever undefined again (when the user unpublishes video), we
    // won't set 'supportsFacingMode' to false. This prevents the icon from briefly
    // disappearing when the user switches their front/rear camera.
    if (facingMode && supportsFacingMode === null) {
      setSupportsFacingMode(Boolean(facingMode));
    }
  }, [facingMode, supportsFacingMode]);

  const toggleFacingMode = useCallback(() => {
    const newFacingMode = facingMode === 'user' ? 'environment' : 'user';
    dispatch(flipCamera(newFacingMode))
  }, [dispatch, facingMode]);

  return supportsFacingMode ? (
    <IconButton onClick={toggleFacingMode} disabled={!videoTrack}>
      <FlipCameraIosIcon />
    </IconButton>
  ) : null;
}
