import React from 'react';
import styled from 'styled-components'
import useHeight from "./useHeight";
import {IconButton, useTheme} from "@material-ui/core";
import {TOOLBAR_HEIGHT} from "../theme";

const Layout = ({children, renderParticipants}) => {
    const height = useHeight();
    const theme = useTheme();

    return (
        <StripViewLayout theme={theme} style={{height}}>
            {renderParticipants}
            {children}
        </StripViewLayout>
    )
}

export const Base = styled.div`
    box-sizing: border-box;
    padding: 0;
    margin: 0;
`;

export const Box = styled(Base)`
    width: 100%;
    height: 100%;
`

export const MainContent = styled(Base)`
    width: 100%;
`;

export const MainParticipantWrapper = styled.div`
    height: 100%;
    width: 100%;
    position: relative;
`;

export const GridViewLayout = styled(Base)`
  overflow: hidden;
  width: 100vw;
  height: ${props => props.height ? props.height : `calc(100vh - ${TOOLBAR_HEIGHT}px)`};
  flex-wrap: wrap;
  align-content: center;
  background-color: #181818;
  display: grid;
  grid-template-columns: ${props => `repeat(${props.columns}, minmax(0, 1fr))`};
  grid-template-rows: ${props => `repeat(${props.rows}, 1fr)`};
`

const StripViewLayout = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: grid;
  background-color: #181818;
  grid-template-columns: min(20%, 260px) 1fr;
  grid-template-areas: ". participantList";
  overflow-y: auto;
  video {
    height: 100%;
    max-width: 100%;
  }
  @media (orientation: portrait) {
    grid-template-areas: "participantList .";
    grid-template-columns: auto;
    grid-template-rows: calc(100% - ${props => props.theme.sidebarMobileHeight + 12}px) ${props => props.theme.sidebarMobileHeight + 6}px;
    grid-gap: 6px;
  },
`;

export const ParticipantStrip = styled(Box)`
    width: 100%;
    height: 100%;
    display: grid;
    max-height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    grid-template-columns: 1fr;
    padding: 0.5em;
    grid-auto-rows: 180px;
    
    background-color: #1c1c1c;


    video {
      max-height: 100% !important;
      max-width: 100%;
    }
    
    scrollbar-width: thin;
    scrollbar-color: #333 #111;

    ::-webkit-scrollbar-track {
      background: #111;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #333;
    }
    @media (orientation: landscape) {
    ::-webkit-scrollbar {
          width: 3px;
        }}
    }
    @media (orientation: portrait) {

      max-height: ${props => props.theme.sidebarMobileHeight + 6}px;
      overflow-x: auto;
      overflow-y: hidden;
      grid-template-rows: 1fr;
      grid-template-columns: repeat(40, 180px);
      grid-row: 2;
      grid-column: 1;
      padding: 0;
      video {
          max-height: ${props => props.theme.sidebarMobileHeight + 6}px !important;
          max-width: 100%;
      }
`;


export const ParticipantLayoutDiv = styled(Base)`
    display: grid;
    position: relative;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    width: 100%;
    height: 100%;
    background-color: #111111;
    overflow: hidden;
    border-radius: inherit;
`

export const ParticipantLabel = styled.div`
    position: absolute;
    grid-row: 1;
    grid-column: 1;
    align-self: flex-start;
    color: #ffffff;
    z-index: 3;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    direction: ltr;
    -webkit-tap-highlight-color: transparent;
    -webkit-border-top-left-radius: 8px;
    -webkit-border-top-right-radius: 8px;
    height: 80px;
    width: 100%;
    background-image: -webkit-linear-gradient(top,rgba(0,0,0,0.7) 0,rgba(0,0,0,0.3) 50%,rgba(0,0,0,0) 100%);
    margin-bottom: -80px;
    padding: 10px;
`

export const ParticipantLive = styled.span`
    position: relative;
    color: #ff0000;
    font-weight: 600;
    font-size: 1.2em;
`

export const ParticipantMuted = styled.span`
    position: relative;
    color: #009aee;
    font-weight: 600;
    font-size: 1.2em;
`

export const ParticipantFooterDiv = styled.div`
    position: absolute;
    bottom: 80px;
    grid-row: 1;
    grid-column: 1;
    align-self: flex-start;
    color: #ffffff;
    z-index: 2;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    direction: ltr;
    -webkit-tap-highlight-color: transparent;
    -webkit-border-bottom-left-radius: 8px;
    -webkit-border-bottom-right-radius: 8px;
    height: 80px;
    width: 100%;
    background-image: -webkit-linear-gradient(bottom,rgba(0,0,0,0.7) 0,rgba(0,0,0,0.3) 50%,rgba(0,0,0,0) 100%);
    margin-bottom: -80px;
    padding: 10px;
    display: flex;
    align-items: flex-end;
`

export const ParticipantControls = styled.div`
    display: flex;
    flex-direction: row;
`

export const ParticipantControl = styled(IconButton)`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px;
    color: white;
`

export const ParticipantStyledVideo = styled.video`
    width: 100%;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    border-radius: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background-color: #111111;
`
export const ParticipantVideoPlaceholder = styled.div`
    width: 100%;
    height: 100%;
    grid-row: 1;
    grid-column: 1;
    background-color: #111111;
    display: grid;
    align-items: center;
    justify-content: center;
    color: #ffffff;
`

export default Layout