import React, {useState} from 'react';

import AudioInputList from './AudioInputList';
import AudioOutputList from './AudioOutputList';
import {Dialog, IconButton, DialogContent, Button} from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import VideoInputList from './VideoInputList';
import {createStyles, makeStyles} from '@material-ui/core/styles';
import {useSelector} from "react-redux";

const useStyles = makeStyles((theme) =>
    createStyles({
        container: {
            width: '500px',
            [theme.breakpoints.down('xs')]: {
                width: 'calc(100vw - 32px)',
            },
            '& .inputSelect': {
                width: 'calc(100% - 35px)',
            },
        },
        listSection: {
            margin: '1em 0',
        },
        button: {
            float: 'right',
        },
        paper: {
            [theme.breakpoints.down('xs')]: {
                margin: '16px',
            },
        },
    })
);

export function DeviceSelector() {
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(false);
    const hasMic = useSelector(state => state.audioVideo.localState?.hasMic);
    const bookingRoomState = useSelector((state) => state.bookingDetails?.room);
    const isGroupConsult = bookingRoomState?.booking_summary?.group_consult === 'Yes';
    const isFacilitator = useSelector(state => state.audioVideo?.localState?.isFacilitator);

    const renderInputSelectors = () => {
        if (!isGroupConsult || isFacilitator || (isGroupConsult && hasMic))
            return (
                <React.Fragment>
                    <div className={classes.listSection}>
                        <AudioInputList/>
                    </div>

                    <div className={classes.listSection}>
                        <VideoInputList/>
                    </div>
                </React.Fragment>
            );
        return null
    };

    return (
        <>
            <IconButton onClick={() => setIsOpen(true)} data-cy-device-select>
                <SettingsIcon/>
            </IconButton>
            <Dialog open={isOpen} onClose={() => setIsOpen(false)} classes={{paper: classes.paper}}>
                <DialogContent className={classes.container}>
                    <div className={classes.listSection}>
                        <AudioOutputList/>
                    </div>

                    {renderInputSelectors()}

                    <Button className={classes.button} onClick={() => setIsOpen(false)}>
                        Done
                    </Button>
                </DialogContent>
            </Dialog>
        </>
    );
}
