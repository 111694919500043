import React from "react";
import ParticipantVideo from "./ParticipantVideo";
import Avatar from "./Avatar";
import {ParticipantVideoPlaceholder} from "../../layout/layout";


export default function ParticipantVideoTrack({track, isLocal = false, identity}) {

    if (!track) return null;

    if (track.isEnabled === false) return <Avatar name={identity}/>;
    if (track.isSubscribed === false) return <Avatar name={identity}/>;
    if (track.isStarted === false)
        return <ParticipantVideoPlaceholder>Waiting for video...</ParticipantVideoPlaceholder>;
    if (track.isSwitchedOff === true) return <Avatar name={identity}/>;

    return (
        <ParticipantVideo
            track={track}
            isLocal={track?.name?.includes('camera') && isLocal}
            identity={identity}
        />
    );
}
