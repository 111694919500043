import {
    fetchRoomSubscriptions
} from '../actions';
import {createLocalAudioTrack, createLocalVideoTrack} from "twilio-video";
import {isMobile} from "../../../utils";
import {
    setAudioTrackPublished,
    setDominantSpeaker,
    setLocalTrack,
    setLocalTracks,
    setRoomState, toggleLocalAudio, turnOffLocalDevices,
    updateAVParticipant,
    updateAVParticipants
} from "../../audioVideo/actions";
import {DEFAULT_AV_PARTICIPANT_STATE, getScreenShareStream} from "../../audioVideo/middleware/helpers";
import {setErrorMessage} from "../../app/actions";
import {useSelector} from "react-redux";

export const makeTwilioVideoHandlers = ({dispatch, getState}) => {
    let room;
    let twilioLocalTracks = [];
    let initialLoad = true;

    const onParticipantPublicationsChanged = (publication, participant) => {
        _updateAVParticipant(participant)
    };

    const onParticipantTrackStarted = (track, participant) => {
        _updateAVParticipant(participant)
    }

    const onParticipantTrackSwitched = (track, publication, participant) => {
        _updateAVParticipant(participant)
    }

    const onParticipantConnected = (participant) => {
        console.log(`${participant.identity} connected [${participant.sid}]`);
        _setRoomParticipants();
    }

    const onParticipantDisconnect = (participant) => {
        console.log(`${participant.identity} disconnected [${participant.sid}]`);
        let disconnectedParticipant = createAVParticipant(participant);
        disconnectedParticipant.status = "offline";
        disconnectedParticipant.audioTracks = [];
        disconnectedParticipant.videoTracks = [];
        dispatch(updateAVParticipant(disconnectedParticipant))
        _setRoomParticipants();
    }

    const getAVTrackForPublication = (track) => {
        track.id = track.trackSid;
        track.name = track.trackName;
        track.type = 'twilio';
        track.isEnabled = track.isTrackEnabled;
        track.isSwitchedOff = track.track?.isSwitchedOff
        return track;
    };

    const generateTwilioAVTrack = (track) => {
        if (!track)
            return track
        return {
            id: track.id,
            name: track.name,
            isEnabled: track.isEnabled,
            isStarted: track.isStarted,
            isStopped: track.isStopped,
            track,
            type: 'twilio'
        }
    }

    const getParticipantAudioTracks = (participant) => {
        const tracks = Array.from(participant.tracks.values());
        return tracks.filter((track) => {
            if (track.kind === 'audio')
                return getAVTrackForPublication(track);
            return null;
        })
    };

    const getParticipantVideoTracks = (participant) => {
        const tracks = Array.from(participant.tracks.values());
        return tracks.filter((track) => {
            if (track.kind === 'video')
                return getAVTrackForPublication(track);
            return null;
        })
    };

    const createAVParticipant = (participant) => {
        return {
            ...DEFAULT_AV_PARTICIPANT_STATE,
            id: participant.identity,
            name: participant.identity,
            participantType: "twilio",
            status: "online",
            serviceId: participant.sid,
            audioTracks: getParticipantAudioTracks(participant),
            videoTracks: getParticipantVideoTracks(participant)
        }
    };

    const _updateAVParticipant = (participant) => {
        let updatedAVParticipant = createAVParticipant(participant);
        dispatch(updateAVParticipant(updatedAVParticipant))
    };

    const _setRoomParticipants = () => {
        const roomParticipants = Array.from(room.participants.values());
        let roomAVParticipants = {};
        roomParticipants.forEach(participant => {
            let avParticipant = createAVParticipant(participant);
            roomAVParticipants[avParticipant.id] = avParticipant;
        })
        dispatch(updateAVParticipants(roomAVParticipants));
    };

    const onDisconnect = (room, error) => {
        updateRoomState();
        initialLoad = true;
        if (error){
            dispatch(setErrorMessage("You've been disconnected. Please check your connection and try again"))
            console.log(error)
        }
        window.removeEventListener('beforeunload', onDisconnect);

        if (isMobile) {
            window.removeEventListener('pagehide', onDisconnect);
        }
    }


    let isPublishingVideo = false;
    let isPublishingAudio = false;
    let previousVideoDeviceId;
    let previousAudioDeviceId;

    const getLocalAudioVideoTracks = () => {
        return getState().audioVideo.localTracks;
    };

    const removeTrackFromLocalTracks = (trackToRemove) => {
        twilioLocalTracks = twilioLocalTracks.filter((track) => track.id !== trackToRemove.id);
        const localAVTracks = getLocalAudioVideoTracks();

        if (localAVTracks[trackToRemove.kind]?.id === trackToRemove.id)
            dispatch(setLocalTrack(trackToRemove.kind, null));
    };

    const getLocalVideoTrack = () => twilioLocalTracks.find(track => track?.kind === 'video' && track?.name?.indexOf('screen') === -1);
    const getLocalScreenShareTrack = () => twilioLocalTracks.find(track => track?.kind === 'video' && track?.name?.indexOf('screen') !== -1);

    const stopVideoTrack = (storePreviousDeviceId=true) => {
        if (isPublishingVideo) return;
        isPublishingVideo = true;
        const videoTrack = getLocalVideoTrack();

        if (videoTrack) {
            const localParticipant = room?.localParticipant;

            if (storePreviousDeviceId)
                previousVideoDeviceId = videoTrack.mediaStreamTrack.getSettings().deviceId;

            if (localParticipant)
                localParticipant.unpublishTrack(videoTrack);

            videoTrack.stop();
            removeTrackFromLocalTracks(videoTrack);
        }
        isPublishingVideo = false;
    };

    const unPublishAudioTrack = (audioTrack) => {
        if (isPublishingAudio) return;
        if (!audioTrack) return;

        const localParticipant = room?.localParticipant;

        if (localParticipant){
            isPublishingAudio = true;
            localParticipant.unpublishTrack(audioTrack);
            isPublishingAudio = false;
            dispatch(setAudioTrackPublished(false));
        }
    };

    const publishAudioTrack = (audioTrack) => {
        if (isPublishingAudio) return;
        if (!audioTrack) return;
        const localParticipant = room?.localParticipant;
        if (!localParticipant) return;
        isPublishingAudio = true;
        localParticipant.publishTrack(audioTrack, {priority: 'high'})
            .then(()=>{dispatch(setAudioTrackPublished(true));})
            .catch((err) => {
                isPublishingAudio = false;
                dispatch(setErrorMessage("Failed to publish your audio, please try again"))
                console.log(err)
            }).finally(() => {
                isPublishingAudio = false;
            });
    };

    const stopAudioTrack = (storePreviousDeviceId=true) => {
        if (isPublishingAudio) return;
        isPublishingAudio = true;
        const localAudioTrack = twilioLocalTracks.find(track => track.kind === 'audio');

        if (storePreviousDeviceId)
                previousAudioDeviceId = localAudioTrack.mediaStreamTrack.getSettings().deviceId;

        if (localAudioTrack){
            const localParticipant = room?.localParticipant;

            localAudioTrack.stop();

            if (localParticipant)
                localParticipant.unpublishTrack(localAudioTrack);

            removeTrackFromLocalTracks(localAudioTrack);
        }
        isPublishingAudio = false;
    };

    const createNewAudioTrack = (options = {}) => {
        if (isPublishingAudio) return;

        createLocalAudioTrack({...options})
            .then((track) => {
                twilioLocalTracks = [...twilioLocalTracks, track];
                dispatch(setLocalTrack('audio', generateTwilioAVTrack(track)));
                publishAudioTrack(track);
            })
            .catch((err) => {
                dispatch(setErrorMessage("Failed to get your audio. Please try again"))
                console.log(err)
            })

    }

    const createNewLocalVideoTrack = (options = {}) => {
        if (isPublishingVideo) return;
        const localParticipant = room?.localParticipant;

        isPublishingVideo = true;
        createLocalVideoTrack({...options})
                .then((track) => {
                    dispatch(setLocalTrack('video', generateTwilioAVTrack(track)));
                    twilioLocalTracks= [...twilioLocalTracks, track];

                    if (localParticipant)
                        localParticipant.publishTrack(track, {priority: 'low'})
                            .catch((err) => {
                                isPublishingVideo = false;
                                stopVideoTrack(true);
                                dispatch(setErrorMessage("Failed to publish your video, please try again"));
                                console.log(err)
                            })
                            .finally(() => {
                                isPublishingVideo = false;
                            });

                })
                .catch((err) => {
                    dispatch(setErrorMessage("Failed to get your video. Please try again"))
                    console.log(err)
                    isPublishingVideo = false;
                })
                .finally(() => {
                    isPublishingVideo = false;
                });

    };

    let isPublishingScreen = false;

    const startScreenShare = () => {
        const localParticipant = room?.localParticipant;
        isPublishingScreen = true;
        getScreenShareStream(dispatch)
          .then(stream => {
            const track = stream.getTracks()[0];

            track.name = track.label;
            twilioLocalTracks = [...twilioLocalTracks, track];

            if (localParticipant){
                localParticipant.publishTrack(track, {
                    name: 'screen', // Tracks can be named to easily find them later
                    priority: 'low', // Priority is set to high by the subscriber when the video track is rendered
                  })
                    .catch((err) => {
                        isPublishingScreen = false;
                        stopScreenShareTrack()
                        dispatch(setErrorMessage("Failed to publish your screen, please try again"))
                        console.log(err)
                    })
                    .finally(() => {
                        isPublishingScreen = false;
                    });
            }
          })
          .catch(error => {
              // Don't display an error if the user closes the screen share dialog
              isPublishingScreen = false;
              if (error.name !== 'AbortError' && error.name !== 'NotAllowedError') {
                    dispatch(setErrorMessage("Failed to get your screen, please try again"))
              }
          })
            .finally(() => {
            isPublishingScreen = false;
        });
    };

    const stopScreenShareTrack = () => {
        if (isPublishingScreen) return;
        isPublishingScreen = true;
        const screenShareTrack = getLocalScreenShareTrack();

        if (screenShareTrack) {
            const localParticipant = room?.localParticipant;

            if (localParticipant)
                localParticipant.unpublishTrack(screenShareTrack);

            screenShareTrack.stop();
            removeTrackFromLocalTracks(screenShareTrack);
            dispatch(setLocalTrack("screen", null));
        }
        isPublishingScreen = false;
    };

    const onToggleVideo = () => {
        const videoTrack = getLocalVideoTrack();

        if (videoTrack)
            stopVideoTrack(true);
        else
            createNewLocalVideoTrack({deviceId: {exact: previousVideoDeviceId}, name: previousVideoDeviceId})
    }

    const onToggleScreenShare = () => {
        const screenShareTrack = getLocalScreenShareTrack();

        if(screenShareTrack)
            stopScreenShareTrack()
        else
            startScreenShare()
    }

    const onChangeVideoDevice = (newDevice) => {
        stopVideoTrack(false);
        createNewLocalVideoTrack({deviceId: {exact: newDevice}, name: newDevice})
    }

    const onFlipCamera = (newFacingMode) => {
        stopVideoTrack(true);
        createNewLocalVideoTrack({ facingMode: newFacingMode })
    }

    const isLocalAudioActive = () => {
        const audioTrack = twilioLocalTracks.find(track => track.kind === 'audio');
        return isAudioTrackPublished(audioTrack)
    };

    const isAudioTrackPublished = (audioTrack) => {
        const localParticipant = room?.localParticipant;
        let isPublished = false;

        if (!audioTrack?.name) return false;
        if (!localParticipant) return false;

        localParticipant.audioTracks.forEach((track)=>{
            if (track.trackName === audioTrack.name)
                isPublished = true
        })

        return isPublished
    };

    const onToggleAudio = () => {
        const audioTrack = twilioLocalTracks.find(track => track.kind === 'audio');

        if (!audioTrack) {
            createNewAudioTrack({deviceId: {exact: previousAudioDeviceId}, name: previousAudioDeviceId})
            return
        }

        const localParticipant = room?.localParticipant;
        if (!localParticipant){
            stopAudioTrack();
            return;
        }

        const audioTrackPublished = isAudioTrackPublished(audioTrack);
        if (audioTrackPublished)
            unPublishAudioTrack(audioTrack)
        else
            publishAudioTrack(audioTrack)

    }

    const onChangeAudioDevice = (newDevice) => {
        stopAudioTrack(false);
        createNewAudioTrack({deviceId: {exact: newDevice}, name: newDevice})
    }

    const updateRoomState = () => {
        dispatch(setRoomState(room.state));
        const isGroupConsult = getState().bookingDetails.room?.booking_summary?.group_consult === 'Yes';

        if (room.state === 'connected'){
            onceConnected()
            if (isGroupConsult){
                dispatch(fetchRoomSubscriptions());
            }
        }
    };

    const onDominantSpeakerChanged = (participant) => {
        if (!participant)
            dispatch(setDominantSpeaker(null))
        else
            dispatch(setDominantSpeaker({id: participant.identity}))
    }

    const onceConnected = () => {
        const isFacilitator = getState().audioVideo?.localState?.isFacilitator;
        const isGroupConsult = getState().bookingDetails.room?.booking_summary?.group_consult === 'Yes';
        if (!isFacilitator && isGroupConsult) {
            dispatch(turnOffLocalDevices());
        }
        else {
            twilioLocalTracks.forEach(track => {
                room.localParticipant.publishTrack(track, {priority: track.kind === 'video' ? 'low' : 'high'})
                    .then((publishedTrack)=>{
                        if (publishedTrack.kind === 'audio')
                            dispatch(setAudioTrackPublished(true))
                    })
                    .catch((e) => {
                        console.log(e.message)
                    })
                }
            );
        }
    };

    const onConnectToRoom = async (newRoom) => {
        room = newRoom;
        window.twilioRoom = newRoom;

        updateRoomState();
        room.once('disconnected', onDisconnect);
        _setRoomParticipants()
        room.on('participantConnected', onParticipantConnected);
        room.on('participantDisconnected', onParticipantDisconnect);
        room.on('dominantSpeakerChanged', onDominantSpeakerChanged)

        room.on('trackPublished', onParticipantPublicationsChanged);
        room.on('trackUnpublished', onParticipantPublicationsChanged);

        room.on('trackEnabled', onParticipantPublicationsChanged)
        room.on('trackDisabled', onParticipantPublicationsChanged)

        room.on('trackStarted', onParticipantTrackStarted);

        room.on('trackSwitchedOff', onParticipantTrackSwitched)
        room.on('trackSwitchedOn', onParticipantTrackSwitched)

        room.on('reconnected', updateRoomState);
        room.on('reconnecting', updateRoomState);

        const disconnect = () => newRoom.disconnect()
        // Add a listener to disconnect from the room when a user closes their browser
        window.addEventListener('beforeunload', disconnect);

        if (isMobile) {
            // Add a listener to disconnect from the room when a mobile user closes their browser
            window.addEventListener('pagehide', disconnect);
        }
    }

    const cleanupRoom = async () => {
        if (!room) return;

        room.disconnect()
        room.off('disconnected', onDisconnect)
        room.off('participantConnected', onParticipantConnected)
        room.off('participantDisconnected', onParticipantDisconnect)
        room.off('dominantSpeakerChanged', onDominantSpeakerChanged)

        room.off('trackPublished', onParticipantPublicationsChanged);
        room.off('trackUnpublished', onParticipantPublicationsChanged);

        room.off('trackStarted', onParticipantTrackStarted);
        room.off('trackEnabled', onParticipantPublicationsChanged)
        room.off('trackDisabled', onParticipantPublicationsChanged)

        room.off('trackSwitchedOff', onParticipantTrackSwitched)
        room.off('trackSwitchedOn', onParticipantTrackSwitched)

        room.off('reconnected', updateRoomState);
        room.off('reconnecting', updateRoomState);
        room = null;
        window.twilioRoom = undefined;


    }

    const updateLocalTracks = (tracks) => {
        const videoTrack = tracks.find(track => track.kind === 'video');
        const audioTrack = tracks.find(track => track.kind === 'audio');

        twilioLocalTracks = tracks;

        dispatch(setLocalTracks({
            video: generateTwilioAVTrack(videoTrack),
            audio: generateTwilioAVTrack(audioTrack),
        }))
    }

    return {
        onToggleVideo,
        onToggleAudio,
        onFlipCamera,
        onConnectToRoom,
        cleanupRoom,
        updateLocalTracks,
        onChangeVideoDevice,
        onChangeAudioDevice,
        onToggleScreenShare
    };

};

