import React from "react";

import {useSelector} from "react-redux";
import ParticipantAudio from "./ParticipantAudio";

const ParticipantAudioTracks = ({participantId}) => {
    const participantAudioTracks = useSelector(state => state.audioVideo.remoteAudioTracks[participantId]);

    return participantAudioTracks.map((track) =>
        <ParticipantAudio
            track={track}
            key={`${participantId}-${track.id}`}
            id={`${participantId}-${track.id}`}
        />)

}

export default React.memo(ParticipantAudioTracks);