import React from 'react';
import {ParticipantLabel, ParticipantLive} from "../../layout/layout";
import {useSelector} from "react-redux";

const ParticipantHeader = ({participantID}) => {
    const participants = useSelector(store => store.audioVideo.participants);
    const state = useSelector(state => state.audioVideo.localState)

    const hasMic = useSelector(state => {
        if (participantID === 'local')
            return state.audioVideo.localState?.hasMic
        return false
    });

    const isLocal = participantID === 'local';
    const remoteParticipantName = participants[participantID]?.name ? participants[participantID]?.name : participants[participantID]?.id;
    const nameToDisplay = isLocal ? `${state.name || ""} (You)` : remoteParticipantName;

    return (
        <ParticipantLabel>
            {nameToDisplay}
            {hasMic && <ParticipantLive>&nbsp; - YOU ARE LIVE</ParticipantLive>}
        </ParticipantLabel>
    );
};

export default React.memo(ParticipantHeader);