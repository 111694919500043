import React, {useEffect, useState} from 'react';
import {Mic, MicOff} from "@material-ui/icons";
import {useSelector} from "react-redux";
import {ParticipantControl} from "../../../layout/layout";

const ParticipantAudioIndicator = ({id, onClick}) => {
    const [isEnabled, setIsEnabled] = useState(false);

    const bookingRoomState = useSelector((state) => state.bookingDetails?.room);
    const isGroupConsult = bookingRoomState?.booking_summary?.group_consult === 'Yes';

    const isFacilitator = useSelector(state => {
        if (id === 'local')
            return state.audioVideo?.localState?.isFacilitator
        return state.audioVideo.participants[id]?.isFacilitator
    });

    const audio = useSelector(store => {
        if (id === 'local')
            return [store.audioVideo.localTracks.audio];
        return store.audioVideo.remoteAudioTracks[id];
    });

    const hasMic = useSelector(state => {
        if (id === 'local')
            return state.audioVideo.localState?.hasMic
        return state.audioVideo.participants[id]?.hasMic
    });

    useEffect(() => {
        setIsEnabled(audio && audio.filter(track => {
            if (id === 'local') return track?.isEnabled
            return track?.isTrackEnabled;
        }).length > 0)
    }, [audio, id])

    if ((isGroupConsult && !hasMic && !isFacilitator) || isEnabled) return null;

    return (
        <ParticipantControl onClick={onClick} size={"small"}>
            {!isEnabled ? <MicOff/> : <Mic/>}
        </ParticipantControl>
    );
};

export default ParticipantAudioIndicator;