import React, {useCallback} from 'react';
import { FormControl, MenuItem, Typography, Select } from '@material-ui/core';
// import LocalAudioLevelIndicator from '../LocalAudioLevelIndicator/LocalAudioLevelIndicator';
import { makeStyles } from '@material-ui/core/styles';
import {useDispatch, useSelector} from "react-redux";
import {setAudioInputDevice} from "../../../redux/audioVideo/actions";

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
});

export default function AudioInputList() {
  const classes = useStyles();
  const audioInputDevices = useSelector(state=>state.audioVideo.devices.audioInput);
  const localAudioTrack = useSelector(state=>state.audioVideo.localTracks.audio?.track);
  const localAudioInputDeviceId = localAudioTrack?.mediaStreamTrack.getSettings().deviceId;

  const dispatch = useDispatch();

  const replaceTrack = useCallback((newDeviceId) => {
    dispatch(setAudioInputDevice(newDeviceId))
  }, [dispatch]);


  return (
    <div className={classes.container}>
      <div className="inputSelect">
        {audioInputDevices.length > 1 ? (
          <FormControl fullWidth>
            <Typography variant="h6">Audio Input:</Typography>
            <Select onChange={e => replaceTrack(e.target.value)} value={localAudioInputDeviceId || ''}>
              {audioInputDevices.map(device => (
                <MenuItem value={device.deviceId} key={device.deviceId}>
                  {device.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <>
            <Typography variant="h6">Audio Input:</Typography>
            <Typography>{localAudioTrack?.mediaStreamTrack.label || 'No Local Audio'}</Typography>
          </>
        )}
      </div>
      {/*<LocalAudioLevelIndicator />*/}
    </div>
  );
}
