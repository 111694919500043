import { createMuiTheme } from '@material-ui/core';

export const TOOLBAR_HEIGHT = 64;

export default createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#1D3443',
    },
    // secondary: {
    //   main: '#D5BEAC'
    // }
  },
  sidebarWidth: 260,
  sidebarMobileHeight: 90,
});
