import React from 'react';
import {createStyles, makeStyles} from '@material-ui/core/styles';

import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import HandUpIcon from '@material-ui/icons/PanTool';
import {useDispatch, useSelector} from "react-redux";
import {setRoomParameterForUser} from "../../redux/booking/actions";

const useStyles = makeStyles((theme) =>
  createStyles({
    fab: {
      margin: theme.spacing(1),
    },
    handUpFAB: {
      margin: theme.spacing(1),
      background: '#63f22f',
      color: 'white',
      '&:hover': {
        background: '#3f951f',
      },
    },
  })
);

export default function ToggleRaiseHandButton(props) {
  const classes = useStyles();
  const isHandUp = useSelector(state => state.audioVideo?.localState?.handUp);
  const hasMic = useSelector(state => state.audioVideo.localState?.hasMic);
  const dispatch = useDispatch();

  const toggleRaiseHand = () => {
    if (!!isHandUp) {
      dispatch(setRoomParameterForUser('handUp', null));
    } else {
      let currentTime = new Date().toISOString();
      dispatch(setRoomParameterForUser('handUp', currentTime));
    }
  };

  if (hasMic) return null;

  return (
    <Tooltip
      {...(props.hidden ? { open: true } : {})}
      title={!!isHandUp ? 'Put Hand Down' : 'Raise Hand'}
      placement={props.hidden ? 'left' : 'top'}
      PopperProps={{ disablePortal: true }}
    >
      <Fab className={!!isHandUp ? classes.handUpFAB : classes.fab} onClick={toggleRaiseHand} disabled={props.disabled}>
        {!!isHandUp ? <HandUpIcon /> : <HandUpIcon />}
      </Fab>
    </Tooltip>
  );
}

ToggleRaiseHandButton.defaultProps = {
  hidden: false,
};
