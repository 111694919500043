import React, {useEffect, useRef} from "react";
import {useSelector} from "react-redux";
import {getAudioSource} from "../../utils/audioStreamUtils";

export default function ParticipantAudio({track, id}) {
    const activeSinkId = useSelector(state => state.audioVideo.audioOutputDevice);
    const audioTrack = track?.track;

    const audioEl = useRef();

    useEffect(() => {
        getAudioSource(audioEl, track)
    }, [track, audioTrack]);

    useEffect(() => {
        if (audioEl?.current?.setSinkId) {
            audioEl.current.setSinkId(activeSinkId)
        }
    }, [activeSinkId]);

    return <audio id={`Audio-${id}`} ref={audioEl}/>
}

