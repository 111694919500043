import React, { useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import CallEnd from '@material-ui/icons/CallEnd';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';

import { useDispatch, useSelector } from 'react-redux';
import EndCallForAllDialog from './EndCallForAllDialog';
import {endCall} from "../../../redux/audioVideo/actions";
import {disconnectEverybodyInCall} from "../../../redux/remoteControl/actions";

const useStyles = makeStyles((theme) =>
  createStyles({
    fab: {
      margin: theme.spacing(1),
    },
    endCallFab: {
      margin: theme.spacing(1),
      background: '#F22F46',
      color: 'white',
    },
  })
);

export default function EndCallButton(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const roomState = useSelector(state => state.bookingDetails?.room);
  const isGroupConsult = roomState?.booking_summary?.group_consult === 'Yes';
  const isFacilitator = useSelector(state => state.audioVideo?.localState?.isFacilitator);
  const [showEndGroupDialog, setShowEndGroupDialog] = useState(false);

  const handleDisconnectClick = () => {
    if (isGroupConsult && isFacilitator) setShowEndGroupDialog(true);
    else disconnect();
  };

  const disconnect = () => {
      dispatch(endCall())
  };

  return (
    <>
      <EndCallForAllDialog
        open={showEndGroupDialog}
        onClose={() => setShowEndGroupDialog(false)}
        onYes={() => {
          setShowEndGroupDialog(false);
          dispatch(disconnectEverybodyInCall());
        }}
        onNo={() => {
          setShowEndGroupDialog(false);
          disconnect();
        }}
      />
      <Tooltip
        {...(props.hidden ? { open: true } : {})}
        title={'End Call'}
        onClick={() => handleDisconnectClick()}
        placement={props.hidden ? 'left' : 'top'}
        PopperProps={{ disablePortal: true }}
      >
        <Fab className={classes.endCallFab}>
          <CallEnd />
        </Fab>
      </Tooltip>
    </>
  );
}

EndCallButton.defaultProps = {
  hidden: false,
};
