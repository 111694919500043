import {
  CONNECTED_FROM_ANOTHER_DEVICE, HAS_MIC_REQUEST, REMOVE_PARTICIPANT_FROM_WAITING_ROOM,
  SET_CONSULT_STARTED,
  SET_IS_READY, SET_PARTICIPANT_IN_WAITING_ROOM,
  SET_TOKEN,
  SET_UNMUTE_FEED,
  UPDATE_ROOM_STATUS,
} from '../actions';
import { CONNECTION_CHANGED } from '../../socket/actions';

const INITIAL_STATE = {
  token: null,
  room: null,
  connectedFromAnotherDevice: false,
  isReady: false,
  consultStarted: false,
  unmuteFeed: null,
  waitingRoom: {},
  hasMicRequest: false
};

const bookingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CONNECTION_CHANGED:
      if (action.connected) {
        return { ...state, connectedFromAnotherDevice: false };
      }
      return { ...state };

    case CONNECTED_FROM_ANOTHER_DEVICE:
      return { ...state, room: null, connectedFromAnotherDevice: true };

    case SET_TOKEN:
      return { ...state, token: action.payload };

    case UPDATE_ROOM_STATUS:
      return { ...state, room: action.payload };

    case SET_IS_READY:
      return { ...state, isReady: action.payload };

    case SET_CONSULT_STARTED:
      return { ...state, consultStarted: action.payload };

    case SET_UNMUTE_FEED:
      return { ...state, unmuteFeed: action.payload };
    case SET_PARTICIPANT_IN_WAITING_ROOM:
      return { ...state, waitingRoom: {...state.waitingRoom, [action.payload.participantId]: action.payload.status} };
    case REMOVE_PARTICIPANT_FROM_WAITING_ROOM: {
      let {[action.payload]:value, ...remainingParticipants} = state.waitingRoom
      return { ...state, waitingRoom:{...remainingParticipants}};
    }
    case HAS_MIC_REQUEST:
      return { ...state, hasMicRequest:action.payload};

    default:
      return state;
  }
};

export default bookingReducer;
