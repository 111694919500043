import Socket from './Socket';
import {
  REQUEST_SOCKET_CONNECT,
  REQUEST_SOCKET_DISCONNECT,
  CONNECT_SOCKET,
  DISCONNECT_SOCKET,
  connectionChanged,
  connectSocket,
  disconnectSocket,
} from '../actions';

import { WEBSOCKET_ROOT_URL } from '../../../consts';
import {messageReceived, SEND_MESSAGE_REQUEST} from "../../booking/actions";

const WEBSOCKET_URL = WEBSOCKET_ROOT_URL;
const MAX_RECONNECTS = 50;

const socketMiddleware = (store) => {
  let token = '';
  let reconnectCount = 0;

  const onConnectionChange = (isConnected) => store.dispatch(connectionChanged(isConnected));
  const onIncomingMessage = (message) => store.dispatch(messageReceived(message));

  const onDisconnect = () =>
    setTimeout(() => {
      const shouldReconnect = store.getState().socket.shouldConnect;

      if (shouldReconnect && reconnectCount < MAX_RECONNECTS) {
        reconnectCount++;
        store.dispatch(connectSocket(token));
      }
    }, 5 * 1000);

  const socket = new Socket(onConnectionChange, onIncomingMessage, onDisconnect);
  return (next) => (action) => {
    switch (action.type) {
      case REQUEST_SOCKET_CONNECT:
        token = action.payload;
        store.dispatch(connectSocket(token));
        break;
      case REQUEST_SOCKET_DISCONNECT:
        store.dispatch(disconnectSocket());
        break;
      case CONNECT_SOCKET:
        socket.connect(`${WEBSOCKET_URL}/?token=${action.payload}`);
        break;
      case DISCONNECT_SOCKET:
        socket.disconnect();
        break;
      case SEND_MESSAGE_REQUEST:
        socket.sendMessage({ ...action.message, token });
        break;
      default:
        break;
    }

    return next(action);
  };
};

export default socketMiddleware;
