import React, {useState} from 'react';
import styled from "styled-components";

const AvatarContainer = styled.div`
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const AvatarDiv = styled.div`
    border-radius: 50%;
    color: #fff;
    width: 71px;
    height: 71px;
    padding: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bolder;
    font-family: sans-serif;
    font-size: 16px;
    background-color: ${props => props.backgroundColor};
    box-shadow: ${props => props.boxShadow};
`;

const colors = ['#00AA55', '#009FD4', '#B381B3', '#939393', '#E3BC00', '#D47500', '#DC2A2A'];

function numberFromText(text) {
    const charCodes = text
        .split('') // => ["A", "A"]
        .map(char => char.charCodeAt(0)) // => [65, 65]
        .join(''); // => "6565"
    return parseInt(charCodes, 10);
}

const Avatar = ({name}) => {
    const [volumeLevel,] = useState('0');
    const initials = (name || '-').substring(0, 1);
    const backgroundColor = colors[numberFromText(name || "") % colors.length]
    const boxShadow = `0px 0px 0px ${volumeLevel}px #343434`

    return (
        <AvatarContainer>
            <AvatarDiv boxShadow={boxShadow} backgroundColor={backgroundColor}>{initials}</AvatarDiv>
        </AvatarContainer>
    );
}

export default React.memo(Avatar);