import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Draggable from 'react-draggable';
import styled from 'styled-components';
import useWidth from "../hooks/useWidth";
import {PARTICIPANT_OFFLINE_STATUSES} from "../consts";
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import {setParticipantHasMic} from "../redux/audioVideo/actions";
import {disableParticipantDevice} from "../redux/remoteControl/actions";
import Button from "@material-ui/core/Button";

function sortByHandUpTime(a, b) {
    if (a.handUp < b.handUp) {
        return -1;
    }
    if (a.handUp > b.handUp) {
        return 1
    }
    return 0;
}

const HandUpDialog = () => {

    const participants = useSelector(state => state.audioVideo.participants);
    const [, setActiveDrag] = useState(0);
    const width = useWidth();
    const [controlledPosition, setControlledPosition] = useState({x: width - (width/100*25), y:100})
    const dispatch = useDispatch();

    const onControlledDrag = (e, position) => {
        const {x, y} = position;
        setControlledPosition({x, y});
    };

    const onStart = () => {
        setActiveDrag(prevState => prevState++);
    };

    const onStop = () => {
        setActiveDrag(prevState => prevState--);
    };
    const dragHandlers = {onStart, onStop};

    const onClick = (e, id) => {
        e.stopPropagation();
        dispatch(setParticipantHasMic(id, true))
        dispatch(disableParticipantDevice('hand', id));
    };

    const handsUpParticipants = Object.keys(participants).filter((participantID) => {
        let participant = participants[participantID];
        let handUp = participant.handUp
        let isOnline = PARTICIPANT_OFFLINE_STATUSES.indexOf(participant.status) < 0;
        return handUp && isOnline
    })
        .map((participantID) => participants[participantID])
        .sort((a, b) => sortByHandUpTime(a, b))

    if (handsUpParticipants.length < 1) return null;

    return (
        <Draggable onDrag={onControlledDrag} position={controlledPosition} bounds={"parent"} handle={"strong"} {...dragHandlers}>
            <DragContainer>
                <TitleDiv>
                    <TitleContent>
                        <span>Hands Up</span>
                        <DragIndicatorIcon/>
                    </TitleContent>
                </TitleDiv>
                <ParticipantList>
                    {handsUpParticipants.map((participant) =>
                        <ParticipantButton key={participant.id} onClick={(e)=>onClick(e, participant.id)}>
                            {participant.name}
                        </ParticipantButton>
                    )}
                </ParticipantList>
            </DragContainer>
        </Draggable>
    );
};

export default HandUpDialog;

const DragContainer = styled.div`
  position: absolute;
  z-index: 10;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  background-color: #0b0b0b;
  overflow: hidden;
  color: white;
  min-width: 20%;
`;

const TitleDiv = styled.strong`
  width: 100%;
  text-align: center;
  cursor: grab;
  -moz-border-radius: 4px 4px 0 0;
`;

const TitleContent = styled.div`
  background-color: #1c1c1c;
  display: grid;
  grid-template-columns: auto 30px;

`;

const ParticipantList = styled.div`
  max-height: 40%;
  overflow-y: auto;
  padding: 5px;
`;

const ParticipantButton = styled(Button)`
  width: 100%;
  color: white;
`;