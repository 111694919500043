import React  from "react";

import { styled } from "@material-ui/core/styles";
import { Typography, Slide } from "@material-ui/core";
import PreConsultActionButton from "./PreConsultActionButton";

const PreJoinContiner = styled("div")({
  display: "flex",
  flexDirection: 'column',
  alignItems: "center",
  justifyItems: "center",
  width: "100%",
  height: "100%"
});

const LightTitle = styled(Typography)({
  fontWeight: 300,
  paddingTop: 15,
  paddingBottom: 15
});


export default (props) => {
  return (
    <Slide in={true} direction='down' timeout={{ enter: 500, exit: 0 }}>

      <PreJoinContiner>
        <LightTitle variant="h4" align='center'>Paused</LightTitle>
        <Typography align='center'>Another device is currently active</Typography>
        <PreConsultActionButton
          type="submit"
          color="primary"
          variant="contained"
          onClick={props.onReconnect}
          // disabled={isConnecting  || isFetching}
        >
          Use this device
        </PreConsultActionButton>
      </PreJoinContiner>
    </Slide>
  )
}