import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function EndCallForAllDialog(props) {
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{'End Group Call'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Do you want to end the call for all participants?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onYes} color="secondary">
          End Call For Everyone
        </Button>
        <Button onClick={props.onNo} color="primary" autoFocus>
          End Call For Me Only
        </Button>
      </DialogActions>
    </Dialog>
  );
}
