import {PARTICIPANT_OFFLINE_STATUSES} from "../../../consts";

export const getMainParticipant = (store) => {
    const video = store.audioVideo.localTracks?.video
    const screen = store.audioVideo.localTracks?.screen;
    const localParticipant = {id: 'local', trackId: screen ? screen?.id : video?.id};

    const participants = store.audioVideo.participants;
    const participantVideos = store.audioVideo.remoteVideoTracks;

    const dominantSpeaker = store.audioVideo.dominantSpeaker;
    const pinnedParticipant = store.audioVideo.pinnedParticipant;
    const state = store.audioVideo.localState;

    const hasMicParticipantId = Object.keys(participants).find(participant_id => participants[participant_id].hasMic)
    const facilitatorId = Object.keys(participants).find(participant_id => participants[participant_id].isFacilitator)

    let hasMicParticipant = (hasMicParticipantId && participants[hasMicParticipantId]) ? participants[hasMicParticipantId] : null;
    let facilitator = facilitatorId ? participants[facilitatorId] : null;

    const micParticipantOnline = hasMicParticipant && PARTICIPANT_OFFLINE_STATUSES.indexOf(hasMicParticipant.status) < 0;
    const facilitatorOnline = facilitator && PARTICIPANT_OFFLINE_STATUSES.indexOf(facilitator.status) < 0;

    const onlineMicParticipant = micParticipantOnline ? hasMicParticipant : null;
    const onlineFacilitator = facilitatorOnline ? facilitator : null;

    const participant = pinnedParticipant || onlineMicParticipant || dominantSpeaker || onlineFacilitator || localParticipant;

    const isLocalParticipant = participant.id === 'local';
    const invalidLocalParticipant = isLocalParticipant && (!screen && !video);

    const invalidParticipant = !participant || !participant.id || invalidLocalParticipant;
    const participantName = isLocalParticipant ? `${state.name || ""} (You)` : participants[participant.id]?.name;

    let mainParticipantVideos = participantVideos[participant.id] || [];
    let isScreenTrack = false;

    if (isLocalParticipant){
        isScreenTrack = screen && (!participant.trackId || participant.trackId === screen?.id)
        mainParticipantVideos = isScreenTrack ? [screen] : (video ? [video] : [])
    }

    let videoToRender;
    if (participant.trackId)
        videoToRender = mainParticipantVideos.find(track => track.id === participant.trackId)
    else
        videoToRender = mainParticipantVideos.find(track => track.name === "screen")

    if (!videoToRender && mainParticipantVideos.length > 0) videoToRender = mainParticipantVideos[0];

    return {
        participantName,
        videoToRender,
        invalidParticipant,
        participantID: participant?.id,
        isScreenTrack,
        isLocalParticipant
    }
};