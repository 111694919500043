
const getTwilioAudioSource = (audioRef, audio) => {
    if (!audio || !audio.track){
        audioRef.current.srcObject = null;
        return;
    }

    const el = audioRef.current;

    if (audio.track.isEnabled) {
        el.current = audio.track.attach();
        el.current.setAttribute('data-cy-audio-track-name', audio.name);
        document.body.appendChild(el.current);
        return () => audio.track.detach().forEach(el => el.remove());
    }
};

const getDummyAudio = (audioRef, audio=true) => {
    if (!audio){
        audioRef.current.src = null;
        audioRef.current.setAttribute('autoPlay', false);
        audioRef.current.setAttribute('loop', false);
    }
};

export const getAudioSource = (audioRef, audio) => {
    if (!audio || !audio.type) return;

    switch (audio.type){
        case 'twilio': {
            return getTwilioAudioSource(audioRef, audio)
        }
        case 'mock': {
            return getDummyAudio(audioRef, audio)
        }
        default: {
            return getDummyAudio(audioRef, audio)
        }
    }
};