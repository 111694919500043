import {sendMessage} from "../../booking/actions";
import {endCall} from "../../audioVideo/actions";

export const makeRemoteControlHandlers = ({store}) => {
    const isGroupConsult = () => {
        return store.getState().bookingDetails?.room?.booking_summary?.group_consult === 'Yes';
    };

    const isFacilitator = () => {
        return store.getState().audioVideo?.localState?.isFacilitator;
    };

    const getBookingParticipants = () => {
        let participants = store.getState().audioVideo.participants || {};
        return Object.keys(participants).map(key => key);
    };

    const handleDisconnectAllParticipantsFromCall = () => {
        if (!isGroupConsult() || !isFacilitator()) return;

        let participants = getBookingParticipants();
        participants.forEach(participant => {
            if (participant)
                store.dispatch(
                    sendMessage({
                        action: 'websocket-api',
                        request: 'remote-control',
                        command: `mute_call`,
                        recipient: participant,
                    })
                );
        });
        if (participants.length > 0) {
            store.dispatch(
                sendMessage({
                    action: 'websocket-api',
                    request: 'admission-response',
                    command: 'deny',
                    token_hashes: participants.filter(participant => participant.status !== "offline")
                })
            );
        }

        store.dispatch(endCall());
    };

    const handleMuteEverybodyInCall = () => {
        if (!isGroupConsult() || !isFacilitator()) return;

        let participants = getBookingParticipants();
        participants.forEach(participant => {
            store.dispatch(
                sendMessage({
                    action: 'websocket-api',
                    request: 'remote-control',
                    command: `mute_everyone`,
                    recipient: participant,
                })
            );
        });
    };

    const handleTurnOffParticipantDevice = ({device, participantId}) => {
        if (!isFacilitator()) return;

        store.dispatch(
                sendMessage({
                    action: 'websocket-api',
                    request: 'remote-control',
                    command: `mute_${device}`,
                    recipient: participantId,
                })
            );
    };

    return {
        handleDisconnectAllParticipantsFromCall,
        handleMuteEverybodyInCall,
        handleTurnOffParticipantDevice
    };
}