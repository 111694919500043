import React from "react";
import {Redirect, BrowserRouter as Router, Route, Switch} from "react-router-dom";

import AddToCalender from "./AddToCalender";
import {Consult} from "./components/Consult";

export default function App() {
    return (
        <Router>
            <div className="container">
                <Switch>
                    <Route exact path="/cal/:consult_token_id">
                        <AddToCalender/>
                    </Route>
                    <Route path="/:consult_token_id">
                        <Consult/>
                    </Route>
                    <Redirect to="/" />
                </Switch>
            </div>
        </Router>
    );
}

