import React from 'react';
import {ParticipantControls, ParticipantFooterDiv} from "../../layout/layout";
import ParticipantVideoIndicator from "./indicators/ParticipantVideoIndicator";
import ParticipantAudioIndicator from "./indicators/ParticipantAudioIndicator";
import ScreenShareIndicator from "./indicators/ScreenShareIndicator";
import {useDispatch} from "react-redux";
import {disableParticipantDevice} from "../../redux/remoteControl/actions";
import ParticipantHandUpIndicator from "./indicators/ParticipantHandUpIndicator";
import ParticipantPinnedIndicator from "./indicators/ParticipantPinnedIndicator";
import ParticipantHasMicIndicator from "./indicators/ParticipantHasMicIndicator";

const ParticipantFooter = ({id}) => {
    const dispatch = useDispatch();

    const onInfoClick = (e, feed) => {
        e.stopPropagation();
        dispatch(disableParticipantDevice(feed, id));
    };


    const renderControls = () => {
        return (
            <ParticipantControls>
                <ParticipantHasMicIndicator id={id}/>

                <ParticipantVideoIndicator id={id} onClick={(e)=>onInfoClick(e, 'video')}/>

                <ParticipantAudioIndicator id={id} onClick={(e)=>onInfoClick(e, 'audio')}/>

                <ScreenShareIndicator id={id} onClick={(e)=>onInfoClick(e, 'screen')}/>

                <ParticipantHandUpIndicator id={id} onClick={(e)=>onInfoClick(e,'hand')}/>

                <ParticipantPinnedIndicator id={id}/>
            </ParticipantControls>
        );
    };

    return (
        <ParticipantFooterDiv onClick={(e)=>{e.stopPropagation()}}>
            {renderControls()}
        </ParticipantFooterDiv>
    );
};

export default React.memo(ParticipantFooter);