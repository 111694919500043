import React from 'react';
import {useSelector} from "react-redux";
import HandUpIcon from '@material-ui/icons/PanTool';
import {ParticipantControl} from "../../../layout/layout";

const ParticipantHandUpIndicator = ({id, onClick}) => {
    const isHandUp = useSelector(state => {
        if (id === 'local')
            return state.audioVideo.localState?.handUp
        return state.audioVideo.participants[id]?.handUp
    })
    if (!isHandUp) return null;

    return (
        <ParticipantControl onClick={onClick} size={"small"}>
            {<HandUpIcon style={{color: '#63f22f'}}/>}
        </ParticipantControl>
    );
};

export default ParticipantHandUpIndicator;