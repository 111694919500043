const getTwilioVideoSource = (videoRef, video, priority) => {
    if (!video || !video.track){
        videoRef.current.srcObject = null;
        return;
    }

    const el = videoRef.current;
    el.muted = true;

    if (video.track.setPriority && priority) {
        video.track.setPriority(priority);
    }

    if (video.isEnabled) {
        video.track.attach(el);
        return () => {
            video.track.detach(el);
            if (video.track.setPriority) {
                // Passing `null` to setPriority will set the track's priority to that which it was published with.
                video.track.setPriority(null);
            }
        }
    }
};

const getScreenShareVideo = (videoRef, video) => {
    if (!video){
        videoRef.current.src = null;
        videoRef.current.setAttribute('autoPlay', false);
        videoRef.current.setAttribute('loop', false);
        return;
    }
    videoRef.current.srcObject = video.track;
    videoRef.current.setAttribute('autoPlay', true);

};

const getDummyVideo = (videoRef, video=true) => {
    if (!video){
        videoRef.current.src = null;
        videoRef.current.setAttribute('autoPlay', false);
        videoRef.current.setAttribute('loop', false);
    }
};

export const getVideoSource = (videoRef, video, priority=null) => {
    if (!video || !video.type) return;

    switch (video.type){
        case 'twilio': {
            return getTwilioVideoSource(videoRef, video, priority)
        }
        case 'screen': {
            return getScreenShareVideo(videoRef, video)
        }
        case 'mock': {
            return getDummyVideo(videoRef, video)
        }
        default: {
            return getDummyVideo(videoRef, video)
        }
    }
};

