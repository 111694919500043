import React from "react";

import {useSelector} from "react-redux";
import Participant from "./Participant";

const Participants = () => {

    const orderedParticipants = useSelector(store => store.audioVideo.orderedParticipants);
    const participants = useSelector(store => store.audioVideo.participants);

    return orderedParticipants.filter(participantID => participantID in participants)
        .map((participantID) =>
            <Participant
                participant={participants[participantID]}
                key={participantID}
            />
        )
}

export default React.memo(Participants);