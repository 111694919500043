import React, {useEffect, useRef} from "react";
import {ParticipantStyledVideo} from "../../layout/layout";
import {getVideoSource} from "../../utils/videoStreamUtils";

export default function ParticipantVideo({track, isLocal = false, priority = null}) {
    const ref = useRef(null);
    const videoTrack = track?.track;
    let inputProps = {};

    if (isLocal)
    {
        const isFrontFacing = track?.mediaStreamTrack?.getSettings().facingMode !== 'environment';
        inputProps.style = {transform: isFrontFacing ? 'rotateY(180deg)' : 'none'}
    }

    useEffect(() => {
        getVideoSource(ref, track, priority)
    }, [track, videoTrack, priority]);

    return (
        <ParticipantStyledVideo
            {...inputProps}
            ref={ref}/>
        );
}

