import React from 'react';
import {styled} from '@material-ui/core/styles';
import {Slide, Typography} from '@material-ui/core';
import {useSelector} from 'react-redux';

const PreJoinContiner = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyItems: 'center',
  width: '100%',
  height: '100%',
  zIndex: 4
});

const LightTitle = styled(Typography)({
  fontWeight: 300,
  paddingTop: 15,
  paddingBottom: 15,
});

export default (props) => {
  const permissionRequested = useSelector(s => s.audioVideo?.localState?.admissionStatus);

  const renderMessage = (message) => {
    return (
      <Slide in={true} direction="down" timeout={{ enter: 500, exit: 0 }}>
        <PreJoinContiner>
          <LightTitle
            variant="h4"
            align="center"
          >{`Your consult is with ${props.room?.booking_summary?.practitioner_name}`}</LightTitle>
          <Typography align="center">{message}</Typography>
        </PreJoinContiner>
      </Slide>
    );
  };

  if (!permissionRequested || permissionRequested === 'pending')
    return renderMessage(
      `Please wait for ${props.room?.booking_summary?.practitioner_name} to allow you to join the consult. Your consult will start automatically.`
    );

  return renderMessage(`You have been denied entry to the consult`);
};
