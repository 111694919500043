import React from "react";

import {useDispatch, useSelector} from "react-redux";
import ParticipantVideo from "./ParticipantVideo";
import {
    ParticipantLayoutDiv
} from "../../layout/layout";
import ParticipantFooter from "./ParticipantFooter";
import {setPinnedParticipant} from "../../redux/audioVideo/actions";
import Avatar from "./Avatar";
import ParticipantHeader from "./ParticipantHeader";

function LocalVideo({disablePinning=false}) {
    const video = useSelector(state => state.audioVideo.localTracks?.video)
    const screen = useSelector(state => state.audioVideo.localTracks?.screen)
    const state = useSelector(state => state.audioVideo.localState)
    const dispatch = useDispatch();

    const onSelectVideo =(trackId) => {
        if (!disablePinning){
            dispatch(setPinnedParticipant({trackId, id: "local"}))
        }
    };

    const renderScreen = () => {
        if (!screen) return null;
        return <ParticipantLayoutDiv key={screen?.name} id={"localVideo"}>
                <ParticipantHeader participantID={'local'}/>
                <ParticipantVideo track={screen} key={screen?.name}/>
                <ParticipantFooter id={'local'}/>
        </ParticipantLayoutDiv>
    };


    const renderVideo = () => {
        if (!video) return null;

        return <ParticipantLayoutDiv onClick={()=>{onSelectVideo(video?.id)}} key={video?.id} id={"localVideo"}>
                <ParticipantHeader participantID={'local'}/>
                <ParticipantVideo track={video} isLocal key={video?.name}/>
                <ParticipantFooter id={'local'}/>
        </ParticipantLayoutDiv>
    };

    const renderPlaceholder = () => {
        if (video || screen) return null;

        return (
            <ParticipantLayoutDiv  onClick={()=>{onSelectVideo(null)}}>
                <ParticipantHeader participantID={'local'}/>
                <Avatar name={`${state.name || ""} (You)`}/>
                <ParticipantFooter id={"local"}/>
            </ParticipantLayoutDiv>
        );
    };

    return (
        <React.Fragment>
            {renderVideo()}
            {renderScreen()}
            {renderPlaceholder()}
        </React.Fragment>
    );


}

export default React.memo(LocalVideo)
