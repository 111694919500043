export const SET_CONSULT_STATE = 'SET_CONSULT_STATE';
export const SET_READY_FOR_CONSULT = 'SET_READY_FOR_CONSULT';
export const TOGGLE_VIEW_MODE = "TOGGLE_VIEW_MODE";
export const SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE";
export const SET_SHOW_WAITING_ROOM = "SET_SHOW_WAITING_ROOM";

export const setConsultState = (newState) => ({type: SET_CONSULT_STATE, payload: newState});
export const setReadyForConsult = (isReadyForConsult) => ({type: SET_READY_FOR_CONSULT, payload: isReadyForConsult})
export const toggleViewMode = () => ({type: TOGGLE_VIEW_MODE})
export const setErrorMessage = (errorMessage) => ({type:SET_ERROR_MESSAGE, payload: errorMessage})
export const setShowWaitingRoom = (show) => ({type: SET_SHOW_WAITING_ROOM, payload: show})