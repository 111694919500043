import React, {useCallback} from 'react';
import {FormControl, MenuItem, Typography, Select} from '@material-ui/core';
import {useDispatch, useSelector} from "react-redux";
import {setAudioOutputDevice} from "../../../redux/audioVideo/actions";
// import { useAudioOutputDevices } from '../deviceHooks/deviceHooks';

export default function AudioOutputList() {
    const audioOutputDevices = useSelector(state => state.audioVideo.devices.audioOutput);
    const activeSinkId = useSelector(state => state.audioVideo.audioOutputDevice);
    const activeOutputLabel = audioOutputDevices.find(device => device.deviceId === activeSinkId)?.label;
    const dispatch = useDispatch();

    const setActiveSinkId = useCallback((newId) => {
        dispatch(setAudioOutputDevice(newId))
    }, [dispatch]);

    return (
        <div className="inputSelect">
            {audioOutputDevices.length > 1 ? (
                <FormControl fullWidth>
                    <Typography variant="h6">Audio Output:</Typography>
                    <Select onChange={e => setActiveSinkId(e.target.value)} value={activeSinkId}>
                        {audioOutputDevices.map(device => (
                            <MenuItem value={device.deviceId} key={device.deviceId}>
                                {device.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            ) : (
                <>
                    <Typography variant="h6">Audio Output:</Typography>
                    <Typography>{activeOutputLabel || 'System Default Audio Output'}</Typography>
                </>
            )}
        </div>
    );
}
