import React, {useCallback, useRef} from 'react';

import { createStyles, makeStyles } from '@material-ui/core/styles';

import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import Videocam from '@material-ui/icons/Videocam';
import VideocamOff from '@material-ui/icons/VideocamOff';
import {useDispatch, useSelector} from "react-redux";
import {toggleLocalVideo} from "../../redux/audioVideo/actions";


const useStyles = makeStyles((theme) =>
  createStyles({
    fab: {
      margin: theme.spacing(1),
    },
  })
);

export default function ToggleVideoButton(props) {
  const classes = useStyles();

  const isVideoEnabled = useSelector(state => state.audioVideo?.localTracks?.video?.isEnabled);
  const isFacilitator = useSelector(state => state.audioVideo?.localState?.isFacilitator);
  const hasMic = useSelector(state => state.audioVideo.localState?.hasMic);
  const isGroupConsult = useSelector(state => state.bookingDetails.room?.booking_summary?.group_consult) === 'Yes';

  const lastClickTimeRef = useRef(0);

  const dispatch = useDispatch();

  const toggleVideo = useCallback(() => {
    if (Date.now() - lastClickTimeRef.current > 200) {
      lastClickTimeRef.current = Date.now();
      dispatch(toggleLocalVideo());
    }
  }, [dispatch]);

  if (isGroupConsult && !hasMic && !isFacilitator) return null;

  return (
    <Tooltip
      {...(props.hidden ? { open: true } : {})}
      title={isVideoEnabled ? 'Turn Off Video' : 'Turn On Video'}
      placement={props.hidden ? 'left' : 'top'}
      PopperProps={{ disablePortal: true }}
    >
      <Fab className={classes.fab} onClick={toggleVideo} disabled={props.disabled}>
        {isVideoEnabled ? <Videocam /> : <VideocamOff />}
      </Fab>
    </Tooltip>
  );
}

ToggleVideoButton.defaultProps = {
  hidden: false,
};
