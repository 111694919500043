import {createLocalTracks} from "twilio-video";
import {API_ROOT_URL} from "../../../consts";
import {setErrorMessage} from "../../app/actions";

export const getPreferredVideoDeviceOptions = (devices) => {
    let newDevice = devices.find(device => device.kind === "videoinput" && (device.label).toLowerCase().indexOf("ir") === -1)
    if (!newDevice)
        return {}
    return {deviceId: {exact: newDevice.deviceId}, name: newDevice.label}
};

// [IM]:    This default video constraints results in certain phones jumbling up pixels to give it a corrupted look.
//          removed for now. Suspect its to do with landscape vs portrait.

// const DEFAULT_VIDEO_CONSTRAINTS = {
//     width: 1280,
//     height: 720,
//     frameRate: 24,
// };


export const initTracks = async (options = {}) => {
    return await createLocalTracks({
        video: {
            ...options,
            facingMode: 'user',
            name: `camera-${Date.now()}`
        },
        audio: true,
    });
}

export const fetchToken = async (consult_token_id, dispatch) => {
    const headers = new window.Headers();
    const endpoint = `${API_ROOT_URL}/token_v3/${consult_token_id}`;
    return fetch(endpoint, {headers}).then(res => {
        if (res.ok) return res.json();
        if (!res.ok) {
            if (res.status === 404)
                dispatch(setErrorMessage('Token is invalid or expired. Please make sure you are using the correct link.'));
            if (res.status === 400) {
                res
                    .json()
                    .then(data => {
                        if (data.message) {
                            dispatch(setErrorMessage(data.message));
                        }
                    })
                    .catch(e => {
                        console.log(e)
                        dispatch(setErrorMessage("Could not validate your token. Please try again or contact support."));
                    });
            }
            dispatch(setErrorMessage("Could not validate your token. Please try again or contact support."));
        }
    }).catch((e)=>{
            console.log(e)
            dispatch(setErrorMessage("Could not validate your token. Please try again or contact support."));
    });
}

// export const fetchToken = async (user_name) => {
//     const response = await fetch('https://r0klkvppt7.execute-api.af-south-1.amazonaws.com/prod/issue-token',
//         {
//             method: 'POST',
//             body: JSON.stringify({user_name})
//         }
//     )
//         .then(response => response.json())
//
//     return response.token
// }