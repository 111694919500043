import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import {useDispatch, useSelector} from "react-redux";
import {unmuteRequest} from "../redux/booking/actions";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import {toggleLocalAudio, toggleLocalVideo} from "../redux/audioVideo/actions";
import {DialogActions} from "@material-ui/core";

const RequestUnmuteDialog = () => {
    const device = useSelector((state) => state.bookingDetails?.unmuteFeed);
    const dispatch = useDispatch();

    const onClose = () => {
        dispatch(unmuteRequest(null))
    };

    const handleAction = (stream) => {
        if (stream === "audio"){
            dispatch(toggleLocalAudio())
        }
        if (stream === "video") {
            dispatch(toggleLocalVideo())
        }
        onClose();
    };

    return (
        <Dialog open={!!device} onClick={onClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Request received from Facilitator</DialogTitle>
            <DialogContent>
                <ContentDiv>
                    <p>Please turn on your {device}</p>
                </ContentDiv>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose()} color="secondary">
                    CANCEL
                </Button>
                <Button onClick={() => handleAction(device)} color="primary">
                    TURN ON {device}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default RequestUnmuteDialog;

const ContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;