import React from 'react';
import styled from "styled-components";
import useIsUserActive from '../../hooks/useIsUserActive';
import {useSelector} from 'react-redux';

import {useTheme} from "@material-ui/core";
import {isMobile} from '../../utils';
import ToggleAudioButton from "./ToggleAudioButton";
import {SpeedDial} from "./SpeedDial";
import ToggleVideoButton from "./ToggleVideoButton";
import ToggleRaiseHandButton from "./ToggleRaiseHandButton";
import ToggleScreenShareButton from "./ToggleScreenShareButton";
import EndCallButton from "./EndCallButton/EndCallButton";
import ToggleViewButton from "./ToggleViewButton";
import ShowWaitingRoomButton from "./ShowWaitingRoomButton";

const Container = styled.div`
    display: flex;
    position: absolute;
    right: 50%;
    transform: translate(50%, 30px);
    bottom: 50px;
    z-index: 3;
    transition: opacity 1.2s, transform 1.2s, visibility 0s 1.2s;
    opacity: 0;
    visibility: hidden;
    max-width: min-content;
    pointer-events:none;
    &.showControls, &:hover {
        pointer-events:auto;
        transition: opacity 0.6s, transform 0.6s, visibility 0s;
        opacity: 1;
        visibility: visible;
        transform: translate(50%, 0px);
      }
      ${props=>props.theme.breakpoints.down('xs')} {
        bottom: ${props => props.theme.sidebarMobileHeight + 3}px,
      }
      .localContainer {
      bottom: 5px;
      }
`;

function Controls({ isLocal } = { isLocal: false}) {
  const isUserActive = useIsUserActive();

  const theme = useTheme();
  const bookingRoomState = useSelector((state) => state.bookingDetails?.room);
  const roomState = useSelector(state => state.audioVideo.roomState);
  const isReconnecting = roomState === "reconnecting";
  const isConnected = roomState === "connected" || isReconnecting;

  const showControls = isUserActive ;
  const isGroupConsult = bookingRoomState?.booking_summary?.group_consult === 'Yes';
  const isFacilitator = useSelector(state => state.audioVideo?.localState?.isFacilitator);

  return (
    <Container theme={theme} className={`${(!!isLocal ? "localContainer" : "")} ${showControls && "showControls"}`}>
      <SpeedDial maxVisible={'auto'} minVisible={3}>
        <ToggleAudioButton disabled={isReconnecting} />
        <ToggleVideoButton disabled={isReconnecting} />
        {isConnected && <EndCallButton />}
        {isConnected && !isMobile && <ToggleScreenShareButton disabled={isReconnecting} />}
        {isConnected && isGroupConsult && !isFacilitator && <ToggleRaiseHandButton disabled={isReconnecting} />}
        {isConnected && isGroupConsult && isFacilitator && !isMobile && <ToggleViewButton disabled={isReconnecting} />}
        {isConnected && isGroupConsult && isFacilitator && <ShowWaitingRoomButton disabled={isReconnecting} />}
      </SpeedDial>
    </Container>
  );
}

export default React.memo(Controls);