import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import {useDispatch, useSelector} from 'react-redux';
import {
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
} from '@material-ui/core';
import {admissionResponse} from "../redux/booking/actions";
import {disableParticipantDevice} from "../redux/remoteControl/actions";
import styled from "styled-components";
import {setShowWaitingRoom} from "../redux/app/actions";


function WaitingRoomDialog() {
    const booking_participants = useSelector((state) => state.audioVideo?.participants);
    const awaitingEntry = useSelector(state => state.bookingDetails.waitingRoom);
    const [checked, setChecked] = useState({});
    const dialogOpen = useSelector(state => state.app.showWaitingRoom);

    const selectedUsers = Object.keys(checked).filter(key => checked[key]);
    const usersSelected = selectedUsers.length > 0;
    const dispatch = useDispatch();


    useEffect(() => {
        if (Object.keys(awaitingEntry).length > 0)
            dispatch(setShowWaitingRoom(true));
    }, [dispatch, awaitingEntry]);

    const handleToggle = (value) => () => {
        const newChecked = {...checked};
        newChecked[value] = !(value in newChecked) || !newChecked[value];
        setChecked(newChecked);
    };

    const handleEndCall = (id) => {
        if (booking_participants?.[id])
            dispatch(disableParticipantDevice('call', id))
    };

    const shouldShowEndCallOptions = (bookingParticipant) => {
        if (!booking_participants?.[bookingParticipant.id]) return false;
        return booking_participants?.[bookingParticipant.id].status === 'online';
    };

    const renderEndCallButton = (participant) => {
        if (shouldShowEndCallOptions(participant))
            return (
                <Button onClick={() => handleEndCall(participant.id)} color="secondary">
                    End Call
                </Button>
            );
        return null;

    }

    const renderDenyButton = (participant) => {
        if (participant.admissionStatus === 'denied')
            return null;
        return (
            <Button onClick={() => handleAction('deny', participant.id)} color="secondary">
                Deny
            </Button>
        );
    }

    const renderAdmitButton = (participant) => {
        if (participant.admissionStatus === 'allowed')
            return null;
        return (
            <Button onClick={() => handleAction('allow', participant.id)} color="primary">
                Admit
            </Button>
        );
    }

    const renderListActions = (participant) => {
        return (
            <ListItemSecondaryAction>
                {renderEndCallButton(participant)}
                {renderDenyButton(participant)}
                {renderAdmitButton(participant)}
            </ListItemSecondaryAction>
        )
    }

    const renderListLineItem = (participant) => {
        const labelId = `checkbox-list-label-${participant.id}`;
        return (
            <ListItem key={participant.id} dense button onClick={handleToggle(participant.id)}>
                <ListItemIcon>
                    <Checkbox
                        edge="start"
                        checked={participant.id in checked && checked[participant.id]}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{'aria-labelledby': labelId}}
                    />
                </ListItemIcon>
                <ListItemText id={labelId} primary={participant.name}/>
                {renderListActions(participant)}
            </ListItem>
        );
    };

    const renderListItems = (listItems) => {
        return (
            <StyledList>
                {listItems.map((participant) => renderListLineItem(participant))}
            </StyledList>
        );
    };

    const onCloseDialog = () => {
        setChecked({});
        dispatch(setShowWaitingRoom(false));
    };

    const handleActionForUsers = (users, action, autoClose) => {
        if (action === 'allow')
            dispatch(admissionResponse('allow', users));
        else if (action === 'deny') {
            dispatch(admissionResponse('deny', users));
            users.forEach(bookingId => {
                handleEndCall(bookingId);
            });
        }
        autoClose && onCloseDialog();
    };

    const handleAction = (action, user) => {
        if (action === 'cancel') {
            onCloseDialog();
            return;
        }

        if (user) {
            handleActionForUsers([user], action, false);
            return;
        }

        let users;
        if (usersSelected)
            users = selectedUsers;
        else
            users = getUsersByStatus('pending').map(user => user.id);
            handleActionForUsers(users, action, true);
    };

    const getUsersByStatus = (usersToRender) => {
        if (!booking_participants) return [];

        return Object.keys(booking_participants)
            .filter(key => booking_participants[key]?.admissionStatus === usersToRender.toLowerCase())
            .map((key, index) => {
                return {...booking_participants[key], id: key};
            });
    };

    const renderList = (usersToRender) => {
        let users = getUsersByStatus(usersToRender);
        if (users.length < 1) return null;

        return (
            <React.Fragment>
                <h4>{usersToRender}:</h4>
                {renderListItems(users)}
            </React.Fragment>
        );
    };

    const renderBulkActions = () => {
        if (usersSelected || Object.keys(awaitingEntry).length > 0)
            return (
                <React.Fragment>
                    <Button onClick={() => handleAction('deny', null)} color="secondary">
                        {usersSelected ? 'Deny Selected' : 'Deny Pending'}
                    </Button>
                    <Button onClick={() => handleAction('allow', null)} color="primary">
                        {usersSelected ? 'Allow Selected' : 'Allow Pending'}
                    </Button>
                </React.Fragment>
            );
        return null;
    };

    return (
        <Dialog open={dialogOpen} onClose={() => handleAction('close', null)} fullWidth={true} maxWidth={'sm'}>
            <DialogTitle>Participants</DialogTitle>
            <DialogContent>
                {renderList('Pending')}
                {renderList('Allowed')}
                {renderList('Denied')}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleAction('cancel', null)} color="primary" autoFocus>
                    Cancel
                </Button>
                {renderBulkActions()}
            </DialogActions>
        </Dialog>
    );
}

export default React.memo(WaitingRoomDialog);

const StyledList = styled(List)`
  width: 100%;
`;