import {
  ADMISSION_RESPONSE,
  connectedFromAnotherDevice,
  CONNECTION_CHANGED,
  hasMicRequest,
  MESSAGE_RECEIVED,
  removeParticipantFromWaitingRoom,
  sendMessage,
  SET_ROOM_PARAMETER_FOR_USER,
  SET_TOKEN_HASH,
  setConsultStarted,
  setParticipantInWaitingRoom,
  setToken,
  START_CONSULT,
  updateRoomStatus,
} from '../actions';
import {requestSocketDisconnect} from "../../socket/actions";
import sha256 from "../../../utils/sha256";
import {turnOffLocalDevices, updateLocalParticipantStatus, updateParticipantStatus} from "../../audioVideo/actions";
import {remoteControlInstruction} from "../../remoteControl/actions";

export const ROOM_STATUS = 'ROOM_STATUS';
export const CONNECTED_FROM_ANOTHER_DEVICE = 'CONNECTED_FROM_ANOTHER_DEVICE';
export const REMOTE_CONTROL = 'REMOTE_CONTROL';

const bookingMiddleware = (store) => {
  let interval;
  let lastRoomStatusTime = Date.now();

  const updateParticipantsStatus = (booking_participants) => {
      const waitingRoom = store.getState().bookingDetails.waitingRoom;
      const localID = store.getState().bookingDetails.token;

      booking_participants.forEach((participant) => {
        if (participant.id !== localID){
          store.dispatch(updateParticipantStatus({...participant}))

          if (!(participant.id in waitingRoom) && participant.admissionStatus === 'pending')
              store.dispatch(setParticipantInWaitingRoom(participant.id, 'pending'));
        }
        else{
            const newState = {...participant};
            const prevState = store.getState().audioVideo.localState;
            if (prevState.hasMic !== newState.hasMic && !newState.isFacilitator){
              if ("hasMic" in prevState){
                  store.dispatch(turnOffLocalDevices())
              }
              if (newState.hasMic){
                  store.dispatch(hasMicRequest(true))
              }
            }
            store.dispatch(updateLocalParticipantStatus({...participant}))
        }
      })
  };

  const onSocketConnected = () => {
    store.dispatch(sendMessage({ action: 'websocket-api', request: 'get-booking-summary' }));
    const poll = () => {
      if (Date.now() - lastRoomStatusTime >= 30000){
        store.dispatch(sendMessage({action: 'websocket-api', request: 'status'}));
      }
    }

    if (!!interval) clearInterval(interval);
    interval = setInterval(poll, 15 * 1000);
    poll();
  }

  return (next) => (action) => {
    next(action);
    switch (action.type) {
      case CONNECTION_CHANGED:
        if (action.connected)
          onSocketConnected();
        else
          clearInterval(interval);
        break;

      case MESSAGE_RECEIVED:
        const { message } = action;
        const { type, payload } = message;

        if (!type) break;

        if (type === ROOM_STATUS) {
          if ("booking" in payload) return;
          lastRoomStatusTime = Date.now();
          let {booking_participants, ...room} = payload;
          updateParticipantsStatus(booking_participants)
          store.dispatch(updateRoomStatus({...room}));
        } else if (type === CONNECTED_FROM_ANOTHER_DEVICE) {
          store.dispatch(connectedFromAnotherDevice());
          store.dispatch(requestSocketDisconnect());
        } else if (type === REMOTE_CONTROL) {
          if (store.getState().bookingDetails?.token === payload.recipient) {
            store.dispatch(remoteControlInstruction(payload.command));
          }
        }
        break;
      case SET_ROOM_PARAMETER_FOR_USER: {
        store.dispatch(sendMessage({action: 'websocket-api', request: 'set-parameter', parameter: action.payload.parameter, value: action.payload.value}));
        break;
      }

      case START_CONSULT:
        store.dispatch(setConsultStarted(true));
        store.dispatch(sendMessage({ action: 'websocket-api', request: 'start-consult' }));
        break;
      case SET_TOKEN_HASH:
        sha256(action.payload).then((hash)=>store.dispatch(setToken(hash)));
        break;
      case ADMISSION_RESPONSE: {
        const {status, users} = action.payload;
        users.forEach((user) => store.dispatch(removeParticipantFromWaitingRoom(user)))
        store.dispatch(sendMessage({action: 'websocket-api', request: 'admission-response', command: status, token_hashes: users}));
        break;
      }
      default:
        break;
    }
  };
};

export default bookingMiddleware;
