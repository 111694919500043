export const MESSAGE_RECEIVED = 'MESSAGE_RECEIVED';
export const SEND_MESSAGE_REQUEST = 'SEND_MESSAGE_REQUEST';
export const SET_TOKEN = 'SET_TOKEN';
export const CONNECTION_CHANGED = 'CONNECTION_CHANGED';
export const UPDATE_ROOM_STATUS = 'UPDATE_ROOM_STATUS';
export const CONNECTED_FROM_ANOTHER_DEVICE = 'CONNECTED_FROM_ANOTHER_DEVICE';
export const SET_IS_READY = 'SET_IS_READY';
export const START_CONSULT = 'START_CONSULT';
export const SET_CONSULT_STARTED = 'SET_CONSULT_STARTED';
export const SET_UNMUTE_FEED = 'SET_UNMUTE_FEED';
export const SET_TOKEN_HASH = 'SET_TOKEN_HASH';
export const SET_ROOM_PARAMETER_FOR_USER = "SET_ROOM_PARAMETER_FOR_USER";
export const SET_PARTICIPANT_IN_WAITING_ROOM = "SET_PARTICIPANT_IN_WAITING_ROOM";
export const REMOVE_PARTICIPANT_FROM_WAITING_ROOM = "REMOVE_PARTICIPANT_FROM_WAITING_ROOM";
export const ADMISSION_RESPONSE = "ADMISSION_RESPONSE";
export const HAS_MIC_REQUEST = "HAS_MIC_REQUEST";

export const messageReceived = (message) => {
  return {
    type: MESSAGE_RECEIVED,
    message,
  };
};

export const sendMessage = (message) => {
  return {
    type: SEND_MESSAGE_REQUEST,
    message,
  };
};

export const setToken = (token) => {
  return {
    type: SET_TOKEN,
    payload: token,
  };
};

export const setTokenHash = (token) => ({type: SET_TOKEN_HASH, payload: token});

export const updateRoomStatus = (status) => ({ type: UPDATE_ROOM_STATUS, payload: status });

export const connectedFromAnotherDevice = () => ({ type: CONNECTED_FROM_ANOTHER_DEVICE });
export const setIsReady = (ready) => ({ type: SET_IS_READY, payload: ready });
export const startConsult = () => ({ type: START_CONSULT });
export const setConsultStarted = (started) => ({ type: SET_CONSULT_STARTED, payload: started });
export const unmuteRequest = (feed) => ({ type: SET_UNMUTE_FEED, payload: feed });
export const setRoomParameterForUser = (parameter, value) => ({type: SET_ROOM_PARAMETER_FOR_USER, payload: { parameter, value }});
export const setParticipantInWaitingRoom = (participantId, status) => ({type: SET_PARTICIPANT_IN_WAITING_ROOM, payload: {participantId, status}})
export const removeParticipantFromWaitingRoom = (participantId) => ({type: REMOVE_PARTICIPANT_FROM_WAITING_ROOM, payload: participantId})
export const admissionResponse = (status, users) => ({type: ADMISSION_RESPONSE, payload: {status, users}});
export const hasMicRequest = (newState) => ({type: HAS_MIC_REQUEST, payload: newState})