import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import {useDispatch, useSelector} from "react-redux";
import {setErrorMessage} from "../redux/app/actions";



function ErrorDialog() {
  const errorMessage = useSelector(state => state.app?.errorMessage)
  const dispatch = useDispatch();

  const dismissError = () => {
    dispatch(setErrorMessage(null));
  };
  //
  // const renderRetry = () => {
  //   return (
  //     <Button
  //       onClick={() => {
  //         dismissError();
  //         window.location.reload();
  //       }}
  //       color="primary"
  //       autoFocus
  //     >
  //       RETRY
  //     </Button>
  //   );
  // };

  const renderOk = () => {
    return (
      <Button
        onClick={() => {
          dismissError();
        }}
        color="primary"
        autoFocus
      >
        OK
      </Button>
    );
  };

  return (
    <Dialog
      open={!!errorMessage}
      onClose={() => dismissError()}
      fullWidth={true}
      maxWidth="xs"
    >
      <DialogTitle>An Error has occurred</DialogTitle>
      <DialogContent>
        <DialogContentText>{errorMessage}</DialogContentText>
      </DialogContent>
      <DialogActions>{renderOk()}</DialogActions>
    </Dialog>
  );
}

export default ErrorDialog;
