import React, {useCallback, useEffect, useState} from 'react';
import {createStyles, makeStyles} from '@material-ui/core/styles';
import {Fab} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Tooltip from '@material-ui/core/Tooltip';
import useWidth from '../../hooks/useWidth';
import {useClickOutsideListenerRef} from '../../hooks/useOutsideListenerRef';

const useStyles = makeStyles((theme) => {
  return createStyles({
    cover: {
      position: 'fixed',
      width: '100%',
      top: '0',
      left: '0',
      zIndex: 1100 /* just above title bar */,
      transition: 'opacity 0.2s ease-in-out',
      opacity: 1,
      '& .closed': {
        opacity: '0',
        transition: 'opacity 0.2s ease-in-out, height 0s linear 0.2s',
      },
    },
    actions: {
      position: 'absolute',
      '& .closed': {
        transition: 'top 0s linear 0.2s',
      },
    },
    container: {
      width: '56px',
      textAlign: 'center',
      zIndex: 1110,
      margin: '8px',
    },
    button: {
      transition: 'transform 0.2s ease-in-out',
      '& .closed': {
        transform: 'scale(0, 0)',
      },
    },

    action: {
      position: 'relative',
      marginBottom: '20px',
    },
    tooltip: {
      position: 'absolute',
      right: '75px',
      top: '18px',
      color: 'white',
      whiteSpace: 'nowrap',
      opacity: '1',
      transition: 'opacity 0.2s ease-in-out',
      '& .closed': {
        opacity: '0',
      },
    },
    openedMainSvg: {
      transform: 'rotate(90deg)',
    },
  });
});

export const SpeedDial = (props) => {
  const width = useWidth();
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const [autoCalculatedItems, setAutoCalculatedItems] = useState(1);
  const minVisible = props.minVisible || 1;

  const onClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const ref = useClickOutsideListenerRef(onClose);

  const handleToggle = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (width < (minVisible * 72)) setAutoCalculatedItems(minVisible);
    else {
      setAutoCalculatedItems(Math.floor(width / 72));
    }
  }, [width, minVisible]);

  const children = () => {
    return React.Children.toArray(props.children).filter(child => !!child);
  };

  if (children().length < 1) return null;

  let visibleItems;

  if (typeof props.maxVisible === 'string') visibleItems = children().splice(0, autoCalculatedItems - 1);
  else visibleItems = children().splice(0, props.maxVisible - 1);

  let hiddenItems = children().splice(visibleItems.length, children().length);
  if (hiddenItems.filter(i => !!i).length === 1) {
    visibleItems.push(hiddenItems.filter(i => !!i)[0]);
    hiddenItems = [];
  }

  const renderHiddenItems = (hiddenItems) => {
    return (
      <div className={open ? 'opened' : 'closed'} ref={ref}>
        <div className={`${classes.cover}`} onClick={handleToggle} />
        <div className={classes.container}>
          {open ? (
            <div className={classes.actions} style={{ top: `${hiddenItems.length * -76}px` }}>
              {hiddenItems.map((hiddenChild, i) => {
                if (!hiddenChild) return null;
                return React.cloneElement(hiddenChild, { hidden: true });
              })}
            </div>
          ) : null}
          <Tooltip
            title={open ? 'Hide' : 'More'}
            placement={open ? 'right' : 'top'}
            PopperProps={{ disablePortal: true }}
          >
            <Fab onMouseUp={handleToggle} className={open ? classes.openedMainSvg : ''}>
              <MoreVertIcon />
            </Fab>
          </Tooltip>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      {visibleItems}
      {hiddenItems.filter(i => !!i).length > 0 && renderHiddenItems(hiddenItems)}
    </React.Fragment>
  );
};

SpeedDial.defaultProps = {
  minVisible: 1,
  maxVisible: 'auto',
};
