import React from 'react';

import useSound from 'use-sound';

import { styled } from '@material-ui/core/styles';
import { Typography, Fade } from '@material-ui/core';
import PreConsultActionButton from './PreConsultActionButton';

const PreJoinContiner = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyItems: 'center',
  width: '100%',
  height: '100%',
});

const LightTitle = styled(Typography)({
  fontWeight: 300,
  paddingTop: 15,
  paddingBottom: 15,
});


export default (props) => {
  const [play] = useSound('/sound/join.mp3');
  const [text, setText] = React.useState('');
  const isGroupConsult = props.room?.booking_summary?.group_consult === 'Yes';
  const patientName = props.room?.booking_summary?.patient_name;
  const patientReady = props.room.patient_ready;

  React.useEffect(() => {
    if (patientReady) {
      play();
      setText(`${patientName} is ready`);
    } else {
      setText(`${patientName} has not yet joined.`);
    }
  }, [patientReady, play, patientName]);

  return (
    <Fade in={true} timeout={{ enter: 500, exit: 0 }}>
      <PreJoinContiner>
        <LightTitle variant="h4" align="center">
          Waiting to start...
        </LightTitle>
        <Typography>{text}</Typography>
        {!patientReady && !isGroupConsult && <Typography>An SMS has been sent to the patient</Typography>}
        <PreConsultActionButton
          type="submit"
          color="secondary"
          variant="contained"
          disabled={!patientReady}
          onClick={props.onStartConsult}
        >
          Start consult
        </PreConsultActionButton>
      </PreJoinContiner>
    </Fade>
  );
};
