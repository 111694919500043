import {
    DISCONNECT_EVERYBODY_IN_CALL,
    MUTE_EVERYBODY_IN_CALL,
    REMOTE_CONTROL_INSTRUCTION,
    TURN_OFF_PARTICIPANT_DEVICE
} from '../actions';
import {setRoomParameterForUser, unmuteRequest} from "../../booking/actions";
import {endCall, muteLocalAudio, toggleLocalAudio, toggleLocalVideo, toggleScreenShare} from "../../audioVideo/actions";
import {makeRemoteControlHandlers} from "./handlers";

const remoteControlMiddleware = (store) => {
    const {
        handleMuteEverybodyInCall,
        handleDisconnectAllParticipantsFromCall,
        handleTurnOffParticipantDevice
    } = makeRemoteControlHandlers({store})

    return (next) => (action) => {
        next(action);

        if ([REMOTE_CONTROL_INSTRUCTION, TURN_OFF_PARTICIPANT_DEVICE].indexOf(action.type) === -1) return;

        if (action.type === REMOTE_CONTROL_INSTRUCTION) {
            switch (action.payload) {
                case 'mute_audio': {
                    const isAudioEnabled = store.getState().audioVideo?.localTracks?.audio?.isEnabled;
                    if (isAudioEnabled)
                        store.dispatch(toggleLocalAudio());
                    else
                        store.dispatch(unmuteRequest('audio'))
                    break;
                }
                case 'mute_video': {
                    let isVideoEnabled = store.getState().audioVideo?.localTracks?.video?.isEnabled;
                    if (isVideoEnabled)
                        store.dispatch(toggleLocalVideo());
                    else
                        store.dispatch(unmuteRequest('video'))
                    break;
                }
                case 'mute_screen': {
                    let screen = store.getState().audioVideo.localTracks?.screen;
                    if (!!screen)
                        store.dispatch(toggleScreenShare());
                    break;
                }
                case 'mute_call': {
                    store.dispatch(endCall());
                    break;
                }
                case 'mute_hand': {
                    store.dispatch(setRoomParameterForUser('handUp', null));
                    break;
                }
                case 'mute_everyone': {
                    store.dispatch(muteLocalAudio())
                    break;
                }
                case MUTE_EVERYBODY_IN_CALL:
                    handleMuteEverybodyInCall();
                    break;
                case DISCONNECT_EVERYBODY_IN_CALL:
                    handleDisconnectAllParticipantsFromCall();
                    break;
                case TURN_OFF_PARTICIPANT_DEVICE:
                    handleTurnOffParticipantDevice(action.payload);
                    break;
                default:
                    break;
            }
            return next(action);
        } else {
            switch (action.type) {
                case TURN_OFF_PARTICIPANT_DEVICE:
                    handleTurnOffParticipantDevice(action.payload);
                    break;
                default:
                    break;
            }
        }
    }
};

export default remoteControlMiddleware;
