import {
} from "../../audioVideo/actions";

const INITIAL_STATE = {};

const twilioReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        default:
            return state;
    }
};

export default twilioReducer;
