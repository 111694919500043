import React, {} from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import Fab from '@material-ui/core/Fab';
import ScreenShare from '@material-ui/icons/ScreenShare';
import StopScreenShare from '@material-ui/icons/StopScreenShare';
import Tooltip from '@material-ui/core/Tooltip';
import {isMobile} from "../../utils";
import {toggleScreenShare} from "../../redux/audioVideo/actions";
import {useDispatch, useSelector} from "react-redux";


export const SCREEN_SHARE_TEXT = 'Share Screen';
export const STOP_SCREEN_SHARE_TEXT = 'Stop Sharing Screen';
export const SHARE_IN_PROGRESS_TEXT = 'Cannot share screen when another user is sharing';
export const SHARE_NOT_SUPPORTED_TEXT = 'Screen sharing is not supported with this browser';

const useStyles = makeStyles((theme) =>
  createStyles({
    fab: {
      margin: theme.spacing(1),
      '&[disabled]': {
        color: 'rgba(225, 225, 225, 0.8)',
        backgroundColor: 'rgba(175, 175, 175, 0.6);',
      },
    },
  })
);

export default function ToggleScreenShareButton(props) {
  const classes = useStyles();
  const screen = useSelector(state => state.audioVideo.localTracks?.screen)
  const isFacilitator = useSelector(state => state.audioVideo?.localState?.isFacilitator);
  const hasMic = useSelector(state => state.audioVideo.localState?.hasMic);
  const isScreenShareSupported = !isMobile && navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia;
  const isDisabled = props.disabled || !isScreenShareSupported;

  const dispatch = useDispatch();

  const isScreenShared = !!screen;

  let tooltipMessage = SCREEN_SHARE_TEXT;

  if (isScreenShared) {
    tooltipMessage = STOP_SCREEN_SHARE_TEXT;
  }

  if (!isScreenShareSupported) {
    tooltipMessage = SHARE_NOT_SUPPORTED_TEXT;
  }

  const onToggleScreenShare = () => {
    dispatch(toggleScreenShare())
  };

  if (!hasMic && !isFacilitator) return null;

  return (
    <Tooltip
      {...(props.hidden ? { open: true } : {})}
      title={tooltipMessage}
      placement={props.hidden ? 'left' : 'top'}
      PopperProps={{ disablePortal: true }}
      style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}
    >
      <div>
        {/* The div element is needed because a disabled button will not emit hover events and we want to display
          a tooltip when screen sharing is disabled */}
        <Fab className={classes.fab} onClick={onToggleScreenShare} disabled={isDisabled}>
          {isScreenShared ? <StopScreenShare /> : <ScreenShare />}
        </Fab>
      </div>
    </Tooltip>
  );
}

ToggleScreenShareButton.defaultProps = {
  hidden: false,
};
