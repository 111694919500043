const SOCKET_CONNECTING = 0;
const SOCKET_OPEN = 1;

export default class Socket {

  constructor(onChange, onMessage, onDisconnect) {
    this.onChange = onChange;
    this.onMessage = onMessage;
    this.onDisconnect = onDisconnect;
    this.socket = null;
  }

  connect = (url) => {

    if (this.socket && (this.socket.readyState === SOCKET_OPEN || this.socket.readyState === SOCKET_CONNECTING)) {
      return;
    }

    this.socket = new WebSocket(url);

    this.socket.onopen = (event) => {
      this.onChange(true);
    };

    this.socket.onclose = (event) => {
      this.onChange(false);
      this.onDisconnect();
    };

    this.socket.onmessage = (event) => {
      this.onMessage(JSON.parse(event.data));
    };

  };


  sendMessage = (message) => {

    if (this.socket.readyState === SOCKET_OPEN) {
      // message.action =
      this.socket.send(JSON.stringify(message));
    } else {
      console.error('Cannot emit socket messages. WebSocket not connected.');
    }

  };

  disconnect = () => this.socket.close();
}
