import {setMainParticipant, setOrderedParticipants, updateAVParticipant} from "../actions";
import {PARTICIPANT_OFFLINE_STATUSES} from "../../../consts";
import {getMainParticipant} from "./mainParticipantHelper";

export const makeVideoHandlers = ({dispatch, getState}) => {

    const updateMainParticipant = () => {
        let store = getState();
        let mainParticipant = getMainParticipant(store);
        dispatch(setMainParticipant(mainParticipant))
    };

    const isAudioOnIndicator = () => {
        const isAudioEnabled = getState().audioVideo?.localTracks?.audio?.isEnabled;
        const isAudioPublished = getState().audioVideo?.audioPublished;
        const consultState = getState().app.consultState;
        const inConsult = consultState === 'in-consult';
        return (isAudioEnabled && !inConsult) || (inConsult && isAudioEnabled && isAudioPublished);
    };

    const setParticipantsOrder = () => {
        const participants = getState().audioVideo.participants;

        const facilitators = Object.keys(participants).filter((participantID) => {
            let participant = participants[participantID];
            return participant.isFacilitator && PARTICIPANT_OFFLINE_STATUSES.indexOf(participant.status) < 0
        });

        const otherParticipants = Object.keys(participants).filter((participantID) => {
            let participant = participants[participantID];
            return !(participant.isFacilitator) && PARTICIPANT_OFFLINE_STATUSES.indexOf(participant.status) < 0
        })

        dispatch(setOrderedParticipants([...facilitators, ...otherParticipants]));
        updateMainParticipant();
    }

    const onUpdateAVParticipants = (newAVParticipants) => {
        Object.keys(newAVParticipants).forEach((avParticipantID) => {
            dispatch(updateAVParticipant(newAVParticipants[avParticipantID]));
        })
        setParticipantsOrder();
    };

    return {
        onUpdateAVParticipants,
        setParticipantsOrder,
        updateMainParticipant,
        isAudioOnIndicator
    };
};

