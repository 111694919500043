import React, {useCallback} from 'react';
import { FormControl, MenuItem, Typography, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {useDispatch, useSelector} from "react-redux";
import ParticipantVideo from "../../Participants/ParticipantVideo";
import {setVideoDevice} from "../../../redux/audioVideo/actions";

const useStyles = makeStyles({
  preview: {
    width: '150px',
    margin: '0.5em 0',
  },
});

export default function VideoInputList() {
  const classes = useStyles();
  const videoInputDevices = useSelector(state=>state.audioVideo.devices.videoInput);
  const localVideo = useSelector(state=>state.audioVideo.localTracks.video);

  const localVideoTrack = localVideo?.track;
  const localVideoInputDeviceId = localVideoTrack?.mediaStreamTrack.getSettings().deviceId;

  const dispatch = useDispatch();

  const replaceTrack = useCallback((newDeviceId) => {
    dispatch(setVideoDevice(newDeviceId))
  }, [dispatch]);


  return (
    <div>
      {videoInputDevices.length > 1 ? (
        <FormControl>
          <Typography variant="h6">Video Input:</Typography>
          <Select onChange={e => replaceTrack(e.target.value)} value={localVideoInputDeviceId || ''}>
            {videoInputDevices.map(device => (
              <MenuItem value={device.deviceId} key={device.deviceId}>
                {device.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <>
          <Typography variant="h6">Video Input:</Typography>
          <Typography>{localVideoTrack?.name || 'No Local Video'}</Typography>
        </>
      )}
      {localVideo && (
        <div className={classes.preview}>
          <ParticipantVideo isLocal track={localVideo} />
        </div>
      )}
    </div>
  );
}
