const getRootUrl = stage => {
  if (stage === 'dev') return 'https://telehealth-api-dev.netcaretech.co.za';
  else if (stage === 'prod') return 'https://telehealth-api-prod.netcaretech.co.za';

  // Default
  return 'http://localhost:8888';
};

const getWebsocketUrl = stage => {
  if (stage === 'dev') return 'wss://telehealth-ws-dev.netcaretech.co.za';
  else if (stage === 'prod') return 'wss://telehealth-ws-prod.netcaretech.co.za';

  // Default
  return 'wss://telehealth-ws-dev.netcaretech.co.za';
};

export const PARTICIPANT_OFFLINE_STATUSES = ["offline", "waiting_room", "disconnected"]

export const STAGE = process.env.REACT_APP_STAGE;
export const API_ROOT_URL = getRootUrl(STAGE);
export const WEBSOCKET_ROOT_URL = getWebsocketUrl(STAGE);
