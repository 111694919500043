import {useEffect, useState} from 'react';
import {TOOLBAR_HEIGHT} from "../theme";

export default function useHeight() {
    const [height, setHeight] = useState(window.innerHeight * (window.visualViewport?.scale || 1));



    useEffect(() => {
        const onResize = () => {
            setHeight(window.innerHeight * (window.visualViewport?.scale || 1));
        }
        window.addEventListener('resize', onResize);
        window.addEventListener('orientationchange', onResize);
        return () => {
            window.removeEventListener('resize', onResize);
            window.removeEventListener('orientationchange', onResize);
        };
    },[]);

    return (height - TOOLBAR_HEIGHT) + 'px';
}

