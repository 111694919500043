import React, {useEffect} from "react";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import Layout, {
    GridViewLayout, MainParticipantWrapper,
    ParticipantStrip
} from "../layout/layout";

import {useTheme} from "@material-ui/core";
import Controls from "./Controls/Controls";
import {initVideo} from "../redux/audioVideo/actions";
import LocalVideo from "./Participants/LocalVideo";
import ParticipantsAudio from "./Participants/ParticipantsAudio";
import Participants from "./Participants/Participants";
import {setConsultState} from "../redux/app/actions";
import useGridDimensions from "../layout/useGridDimensions";
import MainParticipant from "./Participants/MainParticipant";
import WaitingRoomDialog from "../dialogs/WaitingRoomDialog";
import RequestUnmuteDialog from "../dialogs/RequestUnmuteDialog";
import useHeight from "../layout/useHeight";
import KeepAwake from "./KeepAwake";
import HasMicRequestDialog from "../dialogs/HasMicRequestDialog";
import HandUpDialog from "../dialogs/HandUpDialog";

export function Room() {
    const theme = useTheme();
    const dispatch = useDispatch();
    const viewMode = useSelector(state => state.app.viewMode);
    const pinnedParticipant = useSelector(state => state.audioVideo.pinnedParticipant);
    const roomState = useSelector(state => state.audioVideo.roomState);
    const isReconnecting = roomState === "reconnecting";
    const isConnected = roomState === "connected" || isReconnecting;
    const hasMic = useSelector(state => state.audioVideo.localState?.hasMic);
    const isFacilitator = useSelector(state => state.audioVideo?.localState?.isFacilitator);
    const height = useHeight();

    const {rows, columns} = useGridDimensions(3);
    useEffect(() => {
        dispatch(initVideo());
        dispatch(setConsultState('in-consult'));
    }, [dispatch]);

    const renderParticipantStrip = () => {
        return (
            <ParticipantStrip theme={theme}>
                {(hasMic || isFacilitator) && <LocalVideo/>}
                <Participants/>
            </ParticipantStrip>
        );
    }

    const renderSpeakerMode = () => {
        return (
            <Layout>
                {renderParticipantStrip()}
                <MainParticipantWrapper>
                    <MainParticipant/>
                    <Controls/>
                </MainParticipantWrapper>
            </Layout>
        );
    };

    const renderGridMode = () => {
        return (
            <GridViewLayout id={"GridView"} rows={rows} columns={columns} height={height}>
                <LocalVideo id={"LocalVideo"}/>
                <Participants id={"ParticipantsComponent"}/>
                <Controls id={"Controls"}/>
            </GridViewLayout>
        );
    }

    const renderWaitingRoomDialog = () => {
        if (!isFacilitator) return null;
        if (!isConnected) return null;
        return (
            <WaitingRoomDialog/>
        );
    };

    const renderUnmuteDialog = () => {
        if (!isConnected) return null;
        return <RequestUnmuteDialog/>;
    };

    const renderHasMicDialog = () => {
        if (!isConnected) return null;
        if (isFacilitator) return null;
        return <HasMicRequestDialog/>
    };

    const renderHandUpDialog = () => {
        if (!isConnected) return null;
        if (!isFacilitator) return null;
        return <HandUpDialog/>
    };

    return (
        <Container id={"RoomContainer"}>
            <KeepAwake/>
            {renderHandUpDialog()}
            {(!!pinnedParticipant || viewMode === "speaker") ? renderSpeakerMode() : renderGridMode()}
            {renderUnmuteDialog()}
            {renderWaitingRoomDialog()}
            {renderHasMicDialog()}
            <ParticipantsAudio id={"ParticipantsAudio"}/>
        </Container>
    )
}

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;