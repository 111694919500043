import React from 'react';

import { styled } from '@material-ui/core/styles';
import { Slide, Typography } from '@material-ui/core';
import PreConsultActionButton from './PreConsultActionButton';

const PreJoinContiner = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyItems: 'center',
  width: '100%',
  height: '100%',
  zIndex: 5
});

const LightTitle = styled(Typography)({
  fontWeight: 300,
  paddingTop: 15,
  paddingBottom: 15,
});

const getText = (room) => {
  if (room === null) return;

  if (room.user_type === 'practitioner') {
    if (room.patient_ready) {
      return `${room?.booking_summary?.patient_name} is ready`;
    } else {
      return `${room?.booking_summary?.patient_name} has not yet joined`;
    }
  }

  if (room.user_type === 'patient') {
    if (room.practitioner_ready) {
      return `Your consult is with ${room?.booking_summary?.practitioner_name}`;
    } else {
      return `Your consult is with ${room?.booking_summary?.practitioner_name}`;
    }
  }

  return null;
};

export default (props) => {
  return (
    <Slide in={true} direction="down" timeout={{ enter: 500, exit: 0 }}>
      <PreJoinContiner>
        <LightTitle variant="h4" align="center">
          Ready to join?
        </LightTitle>
        <Typography>{getText(props.room)}</Typography>
        <PreConsultActionButton
          id="ReadyButton"
          type="submit"
          color="primary"
          variant="contained"
          onClick={props.onReady}
        >
          I am ready
        </PreConsultActionButton>
      </PreJoinContiner>
    </Slide>
  );
};
