import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {ParticipantControl} from "../../../layout/layout";
import {setPinnedParticipant} from "../../../redux/audioVideo/actions";
import PersonPinIcon from '@material-ui/icons/PersonPin';

const ParticipantPinnedIndicator = ({id}) => {
    const pinned = useSelector(state => state.audioVideo.pinnedParticipant?.id)
    const dispatch = useDispatch();
    const isPinned = pinned === id;

    if (!isPinned) return null;

    const onClick = (e) => {
        e.stopPropagation();
        dispatch(setPinnedParticipant(null))
    };

    return (
        <ParticipantControl onClick={(e) => onClick(e)} size={"small"}>
            {<PersonPinIcon/>}
        </ParticipantControl>
    );
};

export default ParticipantPinnedIndicator;