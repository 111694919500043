import { useEffect, useState } from 'react';
import {useSelector} from "react-redux";
import {PARTICIPANT_OFFLINE_STATUSES} from "../consts";

export default function useGridFlexDimensions(initialRows) {
  const [rows, setRows] = useState(initialRows);
  const [columns, setColumns] = useState(4);
  const participants = useSelector(state => state.audioVideo.participants);
  const [onlineParticipants, setOnlineParticipants] = useState(1);

  useEffect(() => {
      let participantsOnline = Object.keys(participants).filter((participantId) => {
        let participantStatus = participants[participantId]?.status;
        return PARTICIPANT_OFFLINE_STATUSES.indexOf(participantStatus) === -1
      })
    setOnlineParticipants(participantsOnline.length + 1)
  }, [participants])

  useEffect(() => {
    if (onlineParticipants < 2) {
      setRows(1);
    } else if (onlineParticipants < 3) {
      setRows(2);
    } else if (onlineParticipants < 7) {
      setRows(2);
    } else if (onlineParticipants < 10) {
      setRows(3);
    } else if (onlineParticipants < 13) {
      setRows(3);
    } else if (onlineParticipants < 17) {
      setRows(4);
    } else {
      setRows(4);
    }
  }, [onlineParticipants]);

  useEffect(()=> {
        setColumns(Math.ceil( onlineParticipants/ rows))
  }, [rows, onlineParticipants])

  return { rows, columns };
}
