import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import {useDispatch, useSelector} from 'react-redux';
import ListAltIcon from '@material-ui/icons/ListAlt';
import {setShowWaitingRoom} from "../../redux/app/actions";

const useStyles = makeStyles((theme) =>
  createStyles({
    fab: {
      margin: theme.spacing(1),
    },
    handUpFAB: {
      margin: theme.spacing(1),
      background: '#63f22f',
      color: 'white',
      '&:hover': {
        background: '#3f951f',
      },
    },
  })
);

export default function ShowWaitingRoomButton(props) {
  const classes = useStyles();
  const isFacilitator = useSelector(state => state.audioVideo?.localState?.isFacilitator);
  const dispatch = useDispatch();
  const isShowing = useSelector(state => state.app.showWaitingRoom);

  const onClick = () => {
      dispatch(setShowWaitingRoom(!isShowing));
  };

  if (isFacilitator)
    return (
      <Tooltip
        {...(props.hidden ? { open: true } : {})}
        title={'Show Participants'}
        placement={props.hidden ? 'left' : 'top'}
        PopperProps={{ disablePortal: true }}
      >
        <Fab className={classes.fab} onClick={() => onClick()} disabled={props.disabled}>
          <ListAltIcon />
        </Fab>
      </Tooltip>
    );

  return null;
}

ShowWaitingRoomButton.defaultProps = {
  hidden: false,
};
