import {
    END_CALL,
    SET_AUDIO_OUTPUT_DEVICE, SET_AUDIO_TRACK_PUBLISHED,
    SET_AV_PARTICIPANTS,
    SET_AV_PARTICIPANTS_AUDIO_TRACKS,
    SET_AV_PARTICIPANTS_VIDEO_TRACKS,
    SET_DOMINANT_SPEAKER,
    SET_LOCAL_DEVICES,
    SET_LOCAL_TRACK,
    SET_LOCAL_TRACKS, SET_MAIN_PARTICIPANT, SET_ORDERED_PARTICIPANTS,
    SET_PINNED_PARTICIPANT,
    SET_ROOM_STATE,
    UPDATE_AV_PARTICIPANT,
    UPDATE_LOCAL_PARTICIPANT_STATE,
    UPDATE_PARTICIPANT_STATUS,
} from "../actions";

const INITIAL_STATE = {
    devices: {
        audioInput: [],
        videoInput: [],
        audioOutput: []
    },
    localTracks: {
        audio: null,
        video: null
    },
    audioOutputDevice: 'default',
    participants: {},
    localState: {},
    remoteAudioTracks: {},
    remoteVideoTracks: {},
    roomState: 'disconnected',
    pinnedParticipant: null,
    dominantSpeaker: null,
    orderedParticipants: [],
    audioPublished: false,
    mainParticipant: {
        participantName: null,
        videoToRender: null,
        invalidParticipant: null,
        participantID: null,
        isScreenTrack: null,
        isLocalParticipant: null
    }
};

const audioVideoReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_LOCAL_DEVICES:
            const devices = action.payload;

            return {
                ...state,
                devices: {
                    audioInput: devices.filter(device => device.kind === 'audioinput'),
                    videoInput: devices.filter(device => device.kind === 'videoinput'),
                    audioOutput: devices.filter(device => device.kind === 'audiooutput')
                }
            }
        case SET_AUDIO_TRACK_PUBLISHED:
            return {...state, audioPublished: action.payload}
        case SET_LOCAL_TRACKS:
            return {...state, localTracks: action.payload}
        case SET_LOCAL_TRACK:
            return {...state, localTracks: {...state.localTracks, [action.payload.trackKind]: action.payload.track}}
        case SET_ROOM_STATE:
            return {...state, roomState: action.payload}
        case END_CALL:
            return {
                ...state,
                participants: {},
                remoteAudioTracks: {},
                remoteVideoTracks: {}
            }
        case SET_AUDIO_OUTPUT_DEVICE:
            return {...state, audioOutputDevice: action.payload}
        case SET_AV_PARTICIPANTS:
            return {...state, participants: action.payload}
        case SET_AV_PARTICIPANTS_AUDIO_TRACKS:
            return {...state, remoteAudioTracks: action.payload}
        case SET_PINNED_PARTICIPANT:
            let newParticipant = action.payload;

            if (newParticipant?.id === state.pinnedParticipant?.id)
                return {...state, pinnedParticipant: null}

            return {...state, pinnedParticipant: newParticipant}
        case SET_DOMINANT_SPEAKER:
            return {...state, dominantSpeaker: action.payload}
        case SET_AV_PARTICIPANTS_VIDEO_TRACKS:
            return {...state, remoteVideoTracks: action.payload}
        case UPDATE_LOCAL_PARTICIPANT_STATE: {
            return {...state, localState: action.payload}
        }
        case UPDATE_PARTICIPANT_STATUS:{
            let {status, ...participant} = action.payload;
            let existingParticipant = {...state.participants[participant.id]}
            if (!existingParticipant["status"])
                existingParticipant["status"] = status;

            return {
                ...state,
                participants: {
                    ...state.participants,
                    [participant.id]: {...existingParticipant, ...participant}
                }
            }
        }

        case UPDATE_AV_PARTICIPANT: {
            let {audioTracks, videoTracks, ...participant} = action.payload;
            let existingParticipant = {...state.participants[participant.id]}
            let {name, isFacilitator, handUp, timeConnected, admissionStatus, hasMic} = {...existingParticipant};
            return {
                ...state,
                participants: {
                    ...state.participants,
                    [participant.id]: {...participant, name, isFacilitator, handUp, timeConnected, admissionStatus, hasMic}
                },
                remoteAudioTracks: {
                    ...state.remoteAudioTracks,
                    [participant.id]: [...audioTracks]
                },
                remoteVideoTracks: {
                    ...state.remoteVideoTracks,
                    [participant.id]: [...videoTracks]
                }
            }
        }
        case SET_ORDERED_PARTICIPANTS:
            return {...state, orderedParticipants: action.payload}
        case SET_MAIN_PARTICIPANT:
            return {...state, mainParticipant: action.payload}

        default:
            return state;
    }
};

export default audioVideoReducer;
