import React from "react";

import {useSelector} from "react-redux";
import {ParticipantLabel, ParticipantLayoutDiv} from "../../layout/layout";
import ParticipantFooter from "./ParticipantFooter";
import ParticipantVideo from "./ParticipantVideo";
import Avatar from "./Avatar";

function MainParticipant() {
    const mainParticipant = useSelector(state => state.audioVideo.mainParticipant)
    const {invalidParticipant, videoToRender, participantName, participantID, isScreenTrack, isLocalParticipant} = mainParticipant;

    if (invalidParticipant || !videoToRender)
        return (
            <ParticipantLayoutDiv id={"mainVideo"}>
                <ParticipantLabel>{participantName}</ParticipantLabel>
                <Avatar name={participantName}/>
                <ParticipantFooter id={participantID}/>
            </ParticipantLayoutDiv>
        );

    return (
        <ParticipantLayoutDiv id={"mainVideo"}>
            <ParticipantLabel>{participantName}</ParticipantLabel>
            <ParticipantVideo track={videoToRender} isLocal={!isScreenTrack && isLocalParticipant}/>
            <ParticipantFooter id={participantID}/>
        </ParticipantLayoutDiv>
    )
}

export default React.memo(MainParticipant)
