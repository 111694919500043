import React from 'react';

import { createStyles, makeStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import CircularProgress from '@material-ui/core/CircularProgress';
import ToggleFullscreenButton from './ToggleFullScreenButton';
import Toolbar from '@material-ui/core/Toolbar';

import FlipCameraButton from './FlipCameraButton';
import { DeviceSelector } from './DeviceSelector/DeviceSelector';

import MainLogo from '../../assets/images/brand_logo.png';
import { Typography } from '@material-ui/core';
import {useSelector} from "react-redux";
import {TOOLBAR_HEIGHT} from "../../theme";
import {ParticipantLive, ParticipantMuted} from "../../layout/layout";
import styled from "styled-components";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.background.paper,
      maxHeight: TOOLBAR_HEIGHT,
      minHeight: TOOLBAR_HEIGHT,
    },
    toolbar: {
      [theme.breakpoints.down('xs')]: {
        padding: 0,
      },
      maxHeight: TOOLBAR_HEIGHT,
      minHeight: TOOLBAR_HEIGHT,
    },
    rightButtonContainer: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 'auto',
    },
    form: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
    },
    loadingSpinner: {
      marginLeft: '1em',
    },
    joinButton: {
      margin: '1em',
    },
    displayName: {
      margin: '1.1em 0.6em',
      color: theme.palette.text.primary,
    },
    logoBold: {
      fontWeight: 'bold',
    },
  })
);

export default function MenuBar() {
  const classes = useStyles();
  const roomState = useSelector(state => state.audioVideo.roomState);
  const consultState = useSelector(state => state.app.consultState);
  const hasMic = useSelector(state => state.audioVideo.localState?.hasMic);
  const { isFetching } = false;
  const isConnecting = roomState === 'connecting';
  const bookingRoomState = useSelector((state) => state.bookingDetails?.room);
  const isGroupConsult = bookingRoomState?.booking_summary?.group_consult === 'Yes';
  const showVideoSettings = consultState !== "post-consult";
  const isFacilitator = useSelector(state => state.audioVideo?.localState?.isFacilitator);

  return (
    <AppBar className={classes.container} position="static">
      <Toolbar className={classes.toolbar}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src={MainLogo} style={{ height: '40px', marginLeft: '5px' }} alt="Logo" />
        </div>

        <Typography className={classes.displayName} variant="body1">
          Virtual<span className={classes.logoBold}>Care</span>
        </Typography>
       {hasMic && <ParticpantLiveFlashing>LIVE</ParticpantLiveFlashing>}
       {isGroupConsult && !hasMic && !isFacilitator && <ParticipantMuted>MUTED</ParticipantMuted>}
       {(isConnecting || isFetching) && <CircularProgress className={classes.loadingSpinner} />}

        {showVideoSettings && !isConnecting && !isFetching && (
          <div className={classes.rightButtonContainer}>
            <FlipCameraButton />
            <DeviceSelector />
            <ToggleFullscreenButton />
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
}

const ParticpantLiveFlashing = styled(ParticipantLive)`
  animation: blink 1s ease-in infinite;
  @keyframes blink {
      from, to { opacity: 1 }
      50% { opacity: 0.3 }
    }
`;
