import React from 'react';
import {Videocam, VideocamOff} from "@material-ui/icons";
import {useSelector} from "react-redux";
import {ParticipantControl} from "../../../layout/layout";

const ParticipantVideoIndicator = ({id, onClick}) => {
    const bookingRoomState = useSelector((state) => state.bookingDetails?.room);
    const isGroupConsult = bookingRoomState?.booking_summary?.group_consult === 'Yes';

    const videos = useSelector(store => {
        if (id === 'local')
            return [store.audioVideo.localTracks.video];
        return store.audioVideo.remoteVideoTracks[id];
    });

    const hasMic = useSelector(state => {
        if (id === 'local')
            return state.audioVideo.localState?.hasMic
        return state.audioVideo.participants[id]?.hasMic
    });

    const isFacilitator = useSelector(state => {
        if (id === 'local')
            return state.audioVideo?.localState?.isFacilitator
        return state.audioVideo.participants[id]?.isFacilitator
    });


    const isVideoEnabled = videos && videos.filter(track => {
        if (!track) return false;
        let trackName = track.trackName ? track.trackName : track.name;
        return trackName.indexOf('screen') === -1;
    }).length > 0;

    if ((isGroupConsult && !hasMic && !isFacilitator) || isVideoEnabled) return null;

    return (
        <ParticipantControl onClick={onClick} size={"small"}>
            {!isVideoEnabled ? <VideocamOff /> : <Videocam />}
        </ParticipantControl>
    );
};

export default ParticipantVideoIndicator;