import React, {useCallback, useEffect} from "react";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {Room} from "./Room";
import {useParams} from "react-router-dom";
import MenuBar from "./MenuBar/MenuBar";
import PreConsult from "./PreConsult/PreConsult";
import useHeight from "../layout/useHeight";
import useWidth from "../hooks/useWidth";
import PostConsult from "./PostConsult/PostConsult";
import {requestSocketConnect} from "../redux/socket/actions";
import {setTokenHash} from "../redux/booking/actions";
import ErrorDialog from "./ErrorDialog";
import RequestMediaPermissions from "./RequestMediaPermissions";

export function Consult() {
    const dispatch = useDispatch();
    const consultState = useSelector(state => state.app.consultState);
    const roomState = useSelector(state => state.audioVideo.roomState);
    const roomActive = roomState === "connected" || roomState === "reconnecting";
    const height = useHeight();
    const width = useWidth();
    let {consult_token_id} = useParams();

    const connectSocket = useCallback(() => {
        dispatch(requestSocketConnect(consult_token_id));
        dispatch(setTokenHash(consult_token_id))
    }, [dispatch, consult_token_id]);

    useEffect(()=> {
        connectSocket();
    }, [connectSocket])

    const renderConsultState = () => {

        if (roomActive)
            return <Room/>

        if (consultState === 'pre-consult')
            return <PreConsult width={width} height={height} connectSocket={connectSocket}/>

        if (consultState === 'request-permission')
            return <RequestMediaPermissions/>

        return <PostConsult/>

    };

    return (
        <Container>
            <MenuBar/>
            <ErrorDialog/>
            {renderConsultState()}
        </Container>

    )
}

const Container = styled.div`
  width: 100%;
  height: 100%;
`;