import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import GridViewIcon from '@material-ui/icons/ViewComfy';
import SpeakerViewIcon from '@material-ui/icons/ViewArray';
import { useDispatch, useSelector } from 'react-redux';
import {toggleViewMode} from "../../redux/app/actions";
import {setPinnedParticipant} from "../../redux/audioVideo/actions";

const useStyles = makeStyles((theme) =>
  createStyles({
    fab: {
      margin: theme.spacing(1),
    },
  })
);

export default function ToggleViewButton(props) {
  const classes = useStyles();
  const currentView = useSelector((state) => state.app.viewMode);
  const dispatch = useDispatch();

  const toggleView = () => {
    if (currentView === 'speaker'){
      dispatch(setPinnedParticipant(null));
    }
    dispatch(toggleViewMode());
  };

  return (
    <Tooltip
      {...(props.hidden ? { open: true } : {})}
      title={currentView === 'speaker' ? 'Switch to Grid View' : 'Switch to Speaker View'}
      placement={props.hidden ? 'left' : 'top'}
      PopperProps={{ disablePortal: true }}
    >
      <Fab className={classes.fab} onClick={toggleView} disabled={props.disabled}>
        {currentView === 'speaker' ? <GridViewIcon /> : <SpeakerViewIcon />}
      </Fab>
    </Tooltip>
  );
}

ToggleViewButton.defaultProps = {
  hidden: false,
};
